import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function Contact(props) {
    const { pathname } = useLocation();
    const { t } = useTranslation("contact");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    //  all values

    const [nameValue, setNameValue] = useState();
    const [email, setEmail] = useState();
    const [subject, setSubject] = useState();
    const [phone, setPhone] = useState();

    const [submitMsg, setSubmitMsg] = useState('');
    const [message, setMessage] = useState('');

    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();
        // Append data to the FormData object

        // bodyFormData.append('wpforms[id]', '12801');
        // bodyFormData.append('wpforms[author]', '1');
        // bodyFormData.append('wpforms[post_id]', '12837');
        bodyFormData.append('name', nameValue);
        bodyFormData.append('email', email);
        bodyFormData.append('subject', subject);
        bodyFormData.append('message', message);
        bodyFormData.append('phone', phone);




        // const headers = {
        //     'fingerprint': localStorage.getItem('fingerPrint')
        // }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/contact-us`, bodyFormData)
            .then(response => {
                // console.log(branchValue);
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    console.log(response.data);
                    // handleShow();
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('Thank You!'))
                        setMessage(t("Your form has been submitted successfully. We'll be in touch shortly."))
                    } else {
                        setSubmitMsg(t('Oppssss!! please Check Your Data'))

                    }
                    // console.log(response.data.targetPath);
                    // file_name:
                    // "wpforms-15001-Omra-Trip-2023-ID-15001-2023-11-08-08-54-33.xlsx"
                    // targetPath:
                    // "https://my-health.sghcairo.net/hr/request45/upload_mena/20231111_104209_886779.xlsx"
                    handleShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    return <>
        {/* <!--page title start--> */}
        <PageTitle title={t('contact')} anotherTitle={t('us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/contact`} currentPage={t('contact us')} />


        {/* <!--page title end--> */}


        {/* <!--contact start--> */}

        <section className="contact-2">
            <div className="container">
                <div className="row g-0 box-shadow">
                    <div className="col-lg-8 col-md-12">
                        <div className="white-bg p-5">
                            <h2 className="title mb-3">{t('title')}
                                <span> {t('anotherTitle')}</span>
                            </h2>
                            <form className="row" onSubmit={submitForm}>
                                <div className="messages"></div>
                                <div className="form-group col-sm-6">
                                    <input id="form_name" type="text" name="name" className="form-control" value={nameValue} onChange={(e) => setNameValue(e.target.value)} placeholder={t('Username')} required="required" data-error={t('requiredUserName')} />
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group col-sm-6">
                                    <input id="form_email" type="email" name="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('email')} required="required" data-error={t('requiredEmail')} />
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group col-sm-6">
                                    <input id="form_phone" type="tel" name="phone" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={t('phone')} required="required" data-error={t('requiredPhone')} />
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group col-sm-6">
                                    <input id="form_subject" type="text" name="Subject" className="form-control" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder={t('subject')} required="required" data-error={t('requiredSubject')} />
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group col-sm-12">
                                    <textarea id="form_message" name="message" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('message')} rows="4" required="required" data-error={t('requiredMsg')}></textarea>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="col-sm-12">
                                    <button className="btn btn-border btn-radius" type='submit'><span>{t('submit')} </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 px-4 py-4 form-info theme-bg text-white d-flex align-items-center">
                        <div>
                            <h2 className="title text-white">{t('contactText')} <span>{t('touch')}</span></h2>

                            <ul className="contact-info list-unstyled mt-4">
                                <li className="mb-4"><i className="flaticon-paper-plane"></i><span>{t('address')}</span>
                                    <p>{t('cairo')}</p>
                                    <p> {t('alx')}</p>
                                </li>
                                <li className="mb-4"><i className="fa-solid fa-phone-volume shake"></i><span>{t('hotlineTitle')}</span><a href="tel:+16259">{t('hotline')}</a>
                                </li>
                                <li><i className="flaticon-email"></i><span>{t('email')}</span><a href="mailto: info.cai@sghgroup.net">{t('emailAddress')}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 my-5 text-center">

                    <Modal   {...props}
                        size="lg"
                        style={customStyles}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered show={show} onHide={handleClose} className="top-0 ">

                        <Modal.Header closeButton>
                            <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                            <Modal.Title style={{ color: "#4ab242" }}>{t('contact us')}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="team-member text-center">
                                <div className="team-description pt-0">
                                    <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                    <p className='lead'>{message}</p>
                                </div>



                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                {t('Close')}
                            </Button>

                        </Modal.Footer>

                    </Modal>
                </div>
            </div>
        </section>

        {/* <!--contact end--> */}



        <div className="page-content">

            {/* <!--map start--> */}

            <section className="p-0 overflow-hidden position-relative">
                <div className="container-fluid p-0 mt-5 mt-lg-0">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="map iframe-h">
                                {/* <iframe className="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%&amp;height=auto&amp;hl=en&amp;q=Josef Teto Street, Nozha, Heliopolis, Cairo, Egypt&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d55224.820293139404!2d31.2842215!3d30.10703530000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sJosef%20Teto%20Street%2C%20Nozha%2C%20Heliopolis%2C%20Cairo%2C%20Egypt!5e0!3m2!1sen!2seg!4v1689075257922!5m2!1sen!2seg" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.840108181602!2d144.95373631539215!3d-37.8172139797516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1497005461921" allowfullscreen=""></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--map end--> */}



        </div>

        {/* <!--body content end--> */}
    </>
}
