import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LocalizationContext } from '../../Context/LocalizationContext'
import { useParams } from 'react-router-dom';
import { GetCategoryDetailsContext } from '../../Context/GetCategoryDetails';
import CopyWrite from '../Utilites/MayoClinic/CopyWrite';
import PageTitle from '../Utilites/PageTitle';
import Cookies from 'js-cookie';
import OwlCarousel from 'react-owl-carousel';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
export default function SlideShowDetails() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 

    const { pathname } = useLocation();

    const options = {
        responsiveclass: true,
        autoplay: true,
        smartSpeed: 1000,
        nav: true,
        dots: false,
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },

            1000: {
                items: 1,

            }
        },
    }
    let { id } = useParams();

    let { slideShowDetails, setSlideShowDetails, getCategoryDetails } = useContext(GetCategoryDetailsContext);
    console.log('kkkk');

    //   console.log(`${diseaseDetails}`);
    useEffect(() => {
        getCategoryDetails(id, setSlideShowDetails);
        // console.log(getCategoryDetails(setDiseaseDetails));
    }, [i18n.language]);

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openSlider = (index) => {
        setSelectedImageIndex(index);
        setIsModalOpen(true);
    };

    const closeSlider = () => {
        setSelectedImageIndex(0);
        setIsModalOpen(false);
    };


    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const images = slideShowDetails.map((item) => item.slides.map((slide) => ({

        original: slide.file_name,
        // thumbnail: item.slides.map((slide) => slide.file_name),
        originalTitle: slide.imageAltText,
        description: slide.caption,

    })));


    return <>


        {/* <!--page title start--> */}
        {slideShowDetails.map((slideShowDetail, index) => (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{slideShowDetail.title}</title>
                <link rel="canonical" href={slideShowDetail.originalUrl} />
            </Helmet>
            {i18n.language ? <PageTitle key={index} title={t('Mayo Clinic')} anotherTitle={t('Health Library')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/SlideShow/${slideShowDetail.docId}`} currentPage={t('HealthStyle')} />
                : <PageTitle key={index} title={t('Health Library')} anotherTitle={t('Mayo Clinic')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/SlideShow/${slideShowDetail.docId}`} currentPage={t('HealthStyle')} />}
        </>))}


        {/* <!--page title end--> */}


        {/* <!--body content start--> */}

        <div className="page-content">
            <section className='p-2'>
                {/* <!--blog start--> */}
                <div className="container">
                    <div className="row">
                        {slideShowDetails.map((slideShowDetail, index) => (<>
                            <div className="col-md-12" key={index}>
                                <div className="post-desc">
                                    <div className="post-title">
                                        <h4><Link to="">{slideShowDetail.title}</Link></h4>
                                    </div>
                                    <div className="post-meta">

                                        <ul className="list-inline">
                                            <li> </li>
                                            <li> {t('Content Type')} : <span className='small'>{slideShowDetail.contentType}</span>  </li>

                                            {/* <li> Videos</li> */}
                                        </ul>
                                        <ul className="list-inline">
                                            <li> </li>
                                            <li>{t('Last Update On')} : <span className='small'>{slideShowDetail.updateDate}</span></li>
                                        </ul>
                                    </div>
                                    {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link className="post-btn" to="/">Read More<i className="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                </div>
                                <div key={index} className="col-lg-8 col-md-12 order-lg-12">
                                    <div className="blog-classNameic">
                                        <div className="post ">

                                            <Carousel>
                                                {slideShowDetail.slides.map((slide) => (
                                                    <Carousel.Item key={slide.id}>
                                                        <img
                                                            className="d-block w-100"
                                                            src={slide.file_name}
                                                            alt={slide.imageAltText}
                                                        />
                                                        <div class="post-desc">

                                                            <div class="post-title">
                                                                <h3 className='text-theme fw-bold'>{slide.title}</h3>
                                                                <p className='lead' dangerouslySetInnerHTML={{ __html: slide.caption }}></p>
                                                            </div>
                                                        </div>

                                                    </Carousel.Item>


                                                ))}
                                            </Carousel>

                                        </div>

                                    </div>
                                </div>
                                <CopyWrite />

                            </div >
                        </>))}
                    </div ></div>
            </section >
        </div >
        {/* <!--body content end-->  */}
    </>
}
