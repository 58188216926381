import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import PageTitle from '../../../Utilites/PageTitle';
import { PatientPortalPinInpContext } from '../../../../Context/Authentication/PatientPortalPinInpContext';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';

export default function IvpDetails() {
    // translation 
    const { t } = useTranslation("CairoIVPDetails");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    let { doc_id } = useParams();
    const [doctorDetails, setDoctorDetails] = useState([]);

    async function getDoctorIvpsDetails(hospital_id, doc_id) {
        let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/details/${hospital_id}/${doc_id}/${Cookies.get('i18next')}`);
        setDoctorDetails(data.Edata);
        console.log('ddddddddddddddddddddddddddddddd ' + doctorDetails);

    }
    useEffect(() => {
        getDoctorIvpsDetails('1', doc_id);
        // console.log('ddddddddddddddddddddddddddddddd ' + doctorDetails);
    }, [])


    // Booking
    let { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);

    const [showModal, setShowModal] = useState(false);
    const [showAvailableSlotsModal, setShowAvailableSlotsModal] = useState(false);
    const [showNextModal, setShowNextModal] = useState(false);
    const [showConfirmedModal, setShowConfirmedModal] = useState(false);
    const [showFreeSlotsModal, setShowFreeSlotsModal] = useState(false);
    const [showForgetPinModal, setShowForgetPinModal] = useState(false);
    const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [visible, setVisible] = useState(6);

    function loadMore() {
        setVisible(visible + 6);
    }


    let [doctorId, setDoctorId] = useState('');
    let [doctorsBooking, setDoctorsBooking] = useState([]);
    let [doctorData, setDoctorData] = useState({});
    let [doctorIdData, setDoctorIdData] = useState('');
    let [doctorDepartmentIdData, setDoctorDepartmentIdData] = useState('');
    let [doctorBranchData, setDoctorBranchData] = useState('');
    let [doctorNameData, setDoctorNameData] = useState('');
    // const [showAvailableSlotsModal, setShowAvailableSlotsModal] = useState(false);
    const handleOpenAvailableSlotsModal = (dep_id, branch, doc_name) => {

        // const ButtonValue = event.target.value;
        // const id = doc_id;ss
        // const docBranch = branch;
        // const dep = dep_id;
        // const name = doc_name;
        // setDoctorId(doc_id);
        setDoctorBranchData(branch);
        setDoctorDepartmentIdData(dep_id);
        setDoctorNameData(doc_name);
        // console.log('doc id ' + doctorId);
        console.log('doc branch ' + doctorBranchData);
        console.log('doc dep-id ' + doctorDepartmentIdData);
        if (doc_id !== '' && doc_id !== undefined && doctorBranchData !== '' && doctorBranchData !== undefined && doctorDepartmentIdData !== undefined && doctorDepartmentIdData !== '') {

            getFreeDays()
            setShowAvailableSlotsModal(true);
        }

        // if (id && docBranch && dep) {
        //     await axios.getFreeDays();
        //     // setShowAvailableSlotsModal(true);
        // }
        // setShowAvailableSlotsModal(true);
        // if (currentPage) {
        //     let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/${Cookies.get('i18next')}?page=${currentPage}`);
        //     // const filteredDoctors = data.Edata.filter((doctor) => doctor.department_name === departments);
        //     console.log(data);
        //     setDoctorsBooking(data.Edata.data);
        //     console.log(doctorsBooking);
        //     if (Array.isArray(doctorsBooking)) {
        //         const doctorList = doctorsBooking.find((doctorBooking) => doctorBooking.doc_id === doctorId);
        //         setDoctorData(doctorList);
        //         setDoctorBranchData(doctorList.branch);
        //         setDoctorDepartmentIdData(doctorList.department_id);
        //         setDoctorIdData(doctorList.doc_id);
        //         console.log('selected dr ' + doctorData);
        //         console.log('selected dr ' + doctorData);
        //         console.log('selected dr ' + doctorDepartmentIdData);
        //         console.log('selected dr ' + doctorBranchData);
        //         console.log('selected dr ' + doctorIdData);
        //         getFreeDays();
        //         setShowAvailableSlotsModal(true);
        //     }
        //     // if (doctorList) {

        //     // if (doctorData.doc_id && doctorData.department_id && doctorData.branch) {

        //     // }

        //     // }
        // }
        // getDoctorsList();


    };


    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             if (doctorId) {
    //                 const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/${Cookies.get('i18next')}?page=${currentPage}`);
    //                 const data = response.data.Edata.data;
    //                 setDoctorsBooking(data);

    //                 const doctorList = data.find((doctorBooking) => doctorBooking.doc_id === doctorId);
    //                 setDoctorData(doctorList);
    //                 setDoctorBranchData(doctorList.branch);
    //                 setDoctorDepartmentIdData(doctorList.department_id);
    //                 setDoctorIdData(doctorList.doc_id);
    //                 getFreeDays();
    //             }
    //         } catch (error) {
    //             // Handle the error
    //             console.error(error);
    //         }
    //     }

    //     fetchData();
    // }, [doctorId]);

    // useEffect(() => {
    //     if (Object.keys(doctorData).length > 0) {
    //         getFreeDays();
    //     // }
    // }, [doctorData])
    const [selectedDate, setSelectedDate] = useState();
    const handleOpenModal = () => {
        setShowModal(true);
        // setSelectedDate(date);
        // setShowAvailableSlotsModal(false)
    };
    const handleOpenNextModal = (date) => {
        setShowModal(false);
        setShowNextModal(true);
        setShowAvailableSlotsModal(false)
        setShowConfirmedModal(false);
        setShowOTPModal(false);
        setShowFirstTimeModal(false);
        setShowForgetPinModal(false);
        setShowFreeSlotsModal(false);
        setSelectedDate(date);
    };
    let [selectedTime, setSelectedTime] = useState('');
    let [selectedTimeTO, setSelectedTimeTO] = useState('');
    function handleOpenConfirmedModal(time, timeTo) {
        // const timeFrom = time;
        // const timeTill = timeTo;
        setSelectedTimeTO(timeTo);
        setSelectedTime(time);
        setShowConfirmedModal(true);
        setShowModal(false);
        setShowNextModal(false);
        setShowAvailableSlotsModal(false)
        setShowForgetPinModal(false);
        setShowFirstTimeModal(false);
        setShowOTPModal(false);
        setShowFreeSlotsModal(false)
        // bookNow();

    };


    const handleOpenForgetPinModal = () => {
        setShowForgetPinModal(true);
        setShowNextModal(false);
        setShowAvailableSlotsModal(false);
        setShowConfirmedModal(false);
        setShowFirstTimeModal(false);
        setShowModal(false);
        setShowOTPModal(false);
        setShowFreeSlotsModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenFirstTimeModal = () => {
        setShowFirstTimeModal(true);
        setShowNextModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenOTPModal = () => {
        setShowOTPModal(true);
        // setShowNextModal(false);
        setShowFirstTimeModal(false);

    };

    const handleCloseAvailableSlotsModal = () => {
        setShowAvailableSlotsModal(false);
        setFreeDays([]);
        setFreeDaysMsg('');
        document.getElementById('showAvailableSlotsModal').classList.add('d-none');

    };
    const handleCloseModal = () => {
        setShowModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseNextModal = () => {
        setShowNextModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseFreeSlotsModal = () => {
        setShowFreeSlotsModal(false);
        // document.querySelector('.modale').classList.add('d-none');
    }
    const handleCloseConfirmedModal = () => {
        setShowConfirmedModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseForgetPinModal = () => {
        setShowForgetPinModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseFirstTimeModal = () => {
        setShowFirstTimeModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseOTPModal = () => {
        setShowOTPModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };

    //  Api for get free_days for Booking
    let [freeDays, setFreeDays] = useState([]);
    let [freeDaysMsg, setFreeDaysMsg] = useState();
    // let [firstFree, setFirstFree] = useState([]);
    let [freeDaysOptions, setFreeDaysOptions] = useState([]);

    const getFreeDays = async () => {

        const bodyFormData = new FormData();
        bodyFormData.append('doc_id', doc_id);
        bodyFormData.append('departmentid', doctorDepartmentIdData);
        // bodyFormData.append('lang',  Cookies.get('i18next'));

        if (pinInp.length > 0) {
            bodyFormData.append('pin', pinInp);
        } else {
            bodyFormData.append('pin', '0');
        }

        bodyFormData.append('branch', doctorBranchData);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }


        try {

            const response = await axios.post('https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/free_days', bodyFormData, { headers });

            if (response.data.Ecode === 200) {
                if (response.data.Edata.length === 0) {
                    setFreeDaysMsg('There Are No Available Slots');
                    setShowAvailableSlotsModal(true);

                } else {
                    setFreeDays(response.data.Edata);
                    // document.getElementById('showOTPModal').classList.add('d-none');
                    // // document.getElementById('ShowAvailableSlotsModal').classList.add('d-none');
                    // document.getElementById('showNextModal').classList.add('d-none');
                    // document.getElementById('showFreeSlotsModal').classList.add('d-none');
                    // document.getElementById('showConfirmedModal').classList.add('d-none');
                    // document.getElementById('showFirstTimeModal').classList.add('d-none');
                    // document.getElementById('showForgetPinModal').classList.add('d-none');
                    setShowAvailableSlotsModal(true);
                    console.log(freeDays);
                    setFreeDaysOptions(response.data.fselect);
                    console.log(response.data.fselect);
                    setFreeDaysMsg('');
                }
            } else {
                setFreeDays(response.data.Edata);

                setFreeDaysMsg(response.data.Emsg);
                console.log(freeDaysMsg);
                // document.getElementById('showOTPModal').classList.add('d-none');
                // // document.getElementById('ShowAvailableSlotsModal').classList.add('d-none');
                // document.getElementById('showNextModal').classList.add('d-none');
                // document.getElementById('showFreeSlotsModal').classList.add('d-none');
                // document.getElementById('showConfirmedModal').classList.add('d-none');
                // document.getElementById('showFirstTimeModal').classList.add('d-none');
                // document.getElementById('showForgetPinModal').classList.add('d-none');
                setShowAvailableSlotsModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const [formData, setFormData] = useState({ pin: '' });
    const [pinNumInp, setPinNumInp] = useState({});
    const handleChange = (e) => {

        let myUser = { ...formData };
        myUser.pin = e.target.value

        setPinNumInp(myUser.pin);

        console.log('setPinNumInp ' + pinNumInp);

    };

    // checkPatientPhone
    const [formPhoneNumData, setFormPhoneNumData] = useState({ phone: '' });
    const [phoneNumInp, setPhoneNumInp] = useState({});
    const [patienName, setPatienName] = useState();
    const [pinError, setPinError] = useState(false);

    const handlePhoneNumChange = (e) => {

        let myUserPhone = { ...formPhoneNumData };
        myUserPhone.phone = e.target.value;

        setPhoneNumInp(myUserPhone.phone);

        console.log('setPinNumInp ' + phoneNumInp);

    };

    async function checkPatientPhone() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', pinNumInp);
        bodyFormData.append('phone', phoneNumInp);
        bodyFormData.append('branch', doctorBranchData);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/checkpatientphone`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setPatienName(response.data.Edata.name)
                    console.log('check phone ' + response.data.Edata);
                    setShowConfirmedModal(false);
                    setShowFreeSlotsModal(true);
                    setShowNextModal(false);
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setShowModal(false);
                    setShowAvailableSlotsModal(false);
                    setPinError(false);
                    getFreeSlots();

                } else {
                    handleOpenNextModal();
                    setPinError(response.data.Emsg);
                    setPinForgetMsg('');
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }



    // free slots 
    const [freeSlots, setFreeSlots] = useState([]);
    const [date, setDate] = useState();
    const [FreeSlotsMsg, setFreeSlotsMsg] = useState();
    let [dateValue, setDateValue] = useState();
    const handleSelectedDate = (dateValue) => {

        setSelectedDate(dateValue.value);
        console.log(selectedDate);
        // setDoctorSelectedOption(doctorSelectedOption);
        // setCurrentPageForSearch(1)

    };

    async function getFreeSlots() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('doc_id', doc_id);
        bodyFormData.append('departmentid', doctorDepartmentIdData);
        bodyFormData.append('pin', pinNumInp);
        bodyFormData.append('branch', doctorBranchData);
        bodyFormData.append('date', selectedDate);
        console.log('selectedDate ' + selectedDate);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/freeslots`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    if (response.data.Edata === '') {
                        setFreeSlotsMsg('OOPs there is no available slots in ');
                    } else {
                        setDate(response.data.Edata.date);
                        setFreeSlots(response.data.Edata.slots);
                        setFreeSlotsMsg('');
                    }
                    // console.log('free slots ' + freeSlots.slots.map(slot => slot.time));

                } else {
                    setFreeSlotsMsg(response.data.Emsg);

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }

    // useEffect(() => {
    //     if (selectedDate) {
    //         getFreeSlots();

    //     }
    // }, [selectedDate])

    // forget pin number
    const [userIdInp, setUserIdInp] = useState();
    const [pinForgetMsg, setPinForgetMsg] = useState();
    const [userPhoneInp, setuserPhoneInp] = useState();
    const [pinForgetMsgError, setPinForgetMsgError] = useState();
    async function forgetPinNumber(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', '0');
        bodyFormData.append('phone', userPhoneInp);
        bodyFormData.append('userid', userIdInp);
        bodyFormData.append('branch', doctorBranchData);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/checkpatientphone`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 202) {
                    // setCheckPhoneNum(response.data.Emsg);
                    // setPinForgetMsg(response.data.Emsg)
                    console.log('handleOpenForgetPinModal ' + response.data);
                    console.log('handleOpenForgetPinModal ' + response.data.Emsg);
                    setPinForgetMsg(response.data.Emsg)
                    setShowNextModal(true);
                    setShowAvailableSlotsModal(false);
                    setShowFreeSlotsModal(false);
                    setShowConfirmedModal(false);
                    setShowFirstTimeModal(false);
                    setShowForgetPinModal(false);
                    setShowOTPModal(false);
                    setShowModal(false);
                    // checkPatientPhone();
                } else {
                    setPinForgetMsgError(response.data.Emsg)

                    handleOpenForgetPinModal();
                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }


    // booking 
    function preventDefault(e) {
        e.preventDefault();
    }
    const [bookingMsg, setBookingMsg] = useState('');
    async function bookNow() {
        const bodyFormData = new FormData();
        if (patientNewPin) {
            bodyFormData.append('pin', -(patientNewPin));

        } else {
            bodyFormData.append('pin', pinNumInp);

        }
        if (patientPhone) {
            bodyFormData.append('phone', patientPhone);

        } else {
            bodyFormData.append('phone', phoneNumInp);

        }
        bodyFormData.append('branch', doctorBranchData);
        bodyFormData.append('date', selectedDate);

        bodyFormData.append('time_from', selectedTime);
        bodyFormData.append('time_to', selectedTimeTO);
        bodyFormData.append('clinic_id', doctorDepartmentIdData);
        bodyFormData.append('doc_id', doc_id);

        console.log('selectedDate ' + selectedDate);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/book`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Ecode === 200) {

                    // Handle the response
                    setBookingMsg(response.data.Emsg);
                    setShowConfirmedModal(true);
                    setShowModal(false);
                    setShowNextModal(false);
                    setShowAvailableSlotsModal(false)
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setShowFreeSlotsModal(false)
                } else {
                    setShowConfirmedModal(true);
                    setShowFreeSlotsModal(false);
                    setShowAvailableSlotsModal(false);
                    setShowNextModal(false);
                    setShowModal(false);
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setBookingMsg(response.data.Emsg);

                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }



    // New Registration 

    const [patientNewPin, setPatientNewPin] = useState('');
    const [patientName, setPatientName] = useState();
    const [patientPhone, setPatientPhone] = useState('');
    const [patientBirthDate, setPatientBirthDate] = useState();
    const [patientGender, setPatientGender] = useState();
    const [patientRegistrationError, setPatientRegistrationError] = useState();
    async function registerNewPatient(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        bodyFormData.append('name', patientName);
        bodyFormData.append('phone', patientPhone);
        bodyFormData.append('birthday', patientBirthDate);
        bodyFormData.append('gender', patientGender);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/RegisterNewPatient`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Emsg === "Success") {
                    // Handle the response
                    console.log(response.data);
                    setPatientNewPin(response.data.Edata.pin);
                    handleOpenOTPModal();
                } else {
                    setPatientRegistrationError(response.data.Emsg);
                    setShowOTPModal(false);
                    setShowFirstTimeModal(true);
                }

                // setCheckPhoneNum(response.data.Emsg);
                // } else {
                //     setShowConfirmedModal(true);
                //     setShowFreeSlotsModal(false);
                //     setShowAvailableSlotsModal(false);
                //     setShowNextModal(false);
                //     setShowForgetPinModal(false);
                //     setShowFirstTimeModal(false);
                //     setShowOTPModal(false);
                //     setBookingMsg(response.data.Emsg);

                // }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }

    // check code for New patient registrtion
    const [patientCode, setPatientCode] = useState();
    const [patientCodeError, setPatientCodeError] = useState();
    async function checkNewPatientCode(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        bodyFormData.append('pin', patientNewPin);
        bodyFormData.append('phone', patientPhone);
        bodyFormData.append('birthday', patientBirthDate);
        bodyFormData.append('gender', patientGender);
        bodyFormData.append('otpcode', patientCode);
        bodyFormData.append('branch', doctorBranchData);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/CheckCode`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Ecode === 200) {
                    // Handle the response
                    // setPatientRegistration(response.data);
                    console.log(response.data);
                    // handleOpenOTPModal();
                    setShowOTPModal(false);
                    setShowFreeSlotsModal(true);

                    // freeSlots();

                    // setShowAvailableSlotsModal(true);

                } else {
                    setShowOTPModal(true);
                    setPatientCodeError(response.data.Emsg)
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        if (selectedTime !== '' && selectedTimeTO !== '') {
            bookNow();
        }
    }, [selectedTime, selectedTimeTO]);

    // useEffect(() => {
    //     if (selectedTime.length > 0 && selectedTimeTO.length > 0 && Object.keys(pinNumInp).length > 0 && Object.keys(phoneNumInp).length > 0 && doctorDepartmentIdData.length > 0 && doc_id.length > 0) {
    //         bookNow();
    //         console.log('selectedTimeTO ' + selectedTimeTO);
    //         console.log('selectedTime ' + selectedTime);
    //     } else if (selectedTime.length > 0 && selectedTimeTO.length > 0 && patientPhone.length > 0 && patientNewPin.length > 0 && doctorDepartmentIdData.length > 0 && doctorId.length > 0) {
    //         bookNow();
    //     } else {


    //     }
    // }, [selectedTime, selectedTimeTO, pinNumInp, phoneNumInp, doctorDepartmentIdData, doctorId, patientPhone, patientNewPin]);
    // let { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);

    // useEffect(() => {
    //     if (doctorId && doctorBranchData && doctorDepartmentIdData && pinInp) {
    //         getFreeDays();
    //         setShowAvailableSlotsModal(true);
    //         // setShowOTPModal(false);

    //     }
    // }, [doctorId, doctorBranchData, doctorDepartmentIdData, pinInp]);

    // useEffect(() => {
    //     getFreeDays();
    // }, [])

    return <>
        <PageTitle title={doctorDetails.famous_name} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/ivp`} prevPage={t('IVP')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/${doctorDetails.doc_id}`} currentPage={doctorDetails.famous_name} />
        {/* <!--page title end--> */}

        {/* <!--body content start--> */}

        <div class="page-content">

            {/* <!--team details start--> */}

            <section>
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-lg-3 col-md-12">
                            <div class="team-single">
                                <div class="team-images">
                                    {doctorDetails.image ? <img className="img-fluid" src={`${doctorDetails.image}`} alt={doctorDetails.famous_name} style={{ marginBottom: '-135px' }} /> : doctorDetails.Sex === 1 ? <img className="img-fluid" src='/images/team/doctor-female.png' alt={doctorDetails.famous_name} /> : <img className="img-fluid" src='/images/team/doctor.png' alt={doctorDetails.famous_name} />}

                                </div>
                                <div class="team-desc">
                                    <div class="social-icons social-colored social-fullwidth">
                                        <ul class="list-inline mb-0">
                                            {/* <li class="social-facebook">
                                                <Link to="" className='fs-6'>
                                                    <i class="fa-solid fa-location-dot px-2"></i>
                                                    Cairo
                                                </Link>
                                            </li> */}
                                            <li class=" w-100 btn btn-theme btn-sm appoint-btn mt-sm-0 ">
                                                <Link onClick={() => handleOpenAvailableSlotsModal(doctorDetails.department_id, doctorDetails.branch, doctorDetails.famous_name)} to="" className="lineHeight w-100 fs-6 btn btn-theme btn-sm appoint-btn mt-sm-0 ">

                                                    {/* <i class="fa-solid fa-graduation-cap pe-2 pt-3"></i>
                                                    {doctorDetails.position} */}
                                                    {t('Book Now')}
                                                </Link>

                                            </li>
                                            {/*
                                            <li class="social-gplus"><Link to="#"><i class="fab fa-google-plus-g" aria-hidden="true"></i></Link>
                                            </li>
                                            <li class="social-linkedin"><Link to="#"><i class="fab fa-linkedin-in" aria-hidden="true"></i></Link>
                                            </li>
                                            <li class="social-rss"><Link to="#"><i class="fas fa-rss" aria-hidden="true"></i></Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-12 mt-3 mt-lg-0">
                            <div class="team-description">
                                <h2 class="title z-index-1 mb-4"> <span className='fw-bold'>{doctorDetails.famous_name}</span></h2>
                                {doctorDetails.department_name ? <h6 class="title"><span className='fw-bold'>{t('department name')} : </span> {doctorDetails.department_name}</h6> : ''}
                                {doctorDetails.position ? <h6 class="title"><span className='fw-bold'>{t('position')} : </span> {doctorDetails.position}</h6> : ''}
                                {doctorDetails.spoken_lang ? <h6 class="title"><span className='fw-bold'>{t('Spoken Language')}: </span> {doctorDetails.spoken_lang}</h6> : ''}
                                {doctorDetails.pio ? <p class="lead mb-0 pb-0 text-justify" dangerouslySetInnerHTML={{ __html: doctorDetails.pio }}></p> : <>
                                    <div class="tab">
                                        {/* <!-- Nav tabs --> */}
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                {doctorDetails.services ? <Link class="nav-item nav-link tabsDoctor" id="nav-tab1" data-bs-toggle="tab" data-bs-target="#tab1-1" type="button" role="tab" aria-selected="true">{t('Services')}</Link> : ''}
                                                {doctorDetails.qualifications ? <Link class="nav-item nav-link tabsDoctor active" id="nav-tab2" data-bs-toggle="tab" data-bs-target="#tab1-2" type="button" role="tab" aria-selected="false">{t('Qualifications')}</Link> : ''}
                                                {doctorDetails.experiences ? <Link class="nav-item nav-link tabsDoctor" id="nav-tab3" data-bs-toggle="tab" data-bs-target="#tab1-3" type="button" role="tab" aria-selected="false">{t('Experience')}</Link> : ''}

                                                {/* <Link class="nav-item nav-link tabsDoctor" id="nav-tab4" data-bs-toggle="tab" data-bs-target="#tab1-2" type="button" role="tab" aria-selected="false">Certifications</Link> */}
                                            </div>
                                        </nav>
                                        {/* <!-- Tab panes --> */}
                                        <div class="tab-content" id="nav-tabContent">
                                            <div role="tabpanel" class="tab-pane fade" id="tab1-1">
                                                {/* <h4>Endocrinology</h4> */}
                                                {doctorDetails.services ? <p class="line-h-2 lead text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.services }}></p> : ''}
                                                {/* <p class="mb-0 text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.experiences }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p> */}
                                            </div>
                                            <div role="tabpanel" class="tab-pane fade show active" id="tab1-2">
                                                {/* <h4>Endocrinology</h4> */}
                                                {doctorDetails.qualifications ? <p class="line-h-2 lead text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.qualifications }}></p> : t('No Data')}
                                                {/* <p class="mb-0 text-grey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p> */}
                                            </div>
                                            <div role="tabpanel" class="tab-pane fade" id="tab1-3">
                                                {/* <h4>Endocrinology</h4> */}
                                                {/* <p class="line-h-2 lead text-grey">Specialty of medicine; some would say a sub-specialty of internal medicine, which deals with the diagnosis and treatment of diseases related to hormones.</p> */}
                                                {doctorDetails.experiences ? <p class="line-h-2 lead text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.experiences }}></p> : t('No Data')}

                                            </div>

                                        </div>
                                    </div>
                                </>}


                            </div>
                        </div>
                    </div >
                </div >
            </section >

            {/* < !--team details end-- > */}


            {/* < !--team tab start-- > */}
            {doctorDetails.pio && (doctorDetails.qualifications || doctorDetails.services || doctorDetails.experiences) ? <>
                <section class="grey-bg mb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="tab">
                                    {/* <!-- Nav tabs --> */}
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            {doctorDetails.services ? <Link class="nav-item nav-link tabsDoctor active" id="nav-tab1" data-bs-toggle="tab" data-bs-target="#tab1-1" type="button" role="tab" aria-selected="true">{t('Services')}</Link> : ''}
                                            {doctorDetails.qualifications ? <Link class="nav-item nav-link tabsDoctor active" id="nav-tab2" data-bs-toggle="tab" data-bs-target="#tab1-2" type="button" role="tab" aria-selected="false">{t('Qualifications')}</Link> : ''}
                                            {doctorDetails.experiences ? <Link class="nav-item nav-link tabsDoctor" id="nav-tab3" data-bs-toggle="tab" data-bs-target="#tab1-3" type="button" role="tab" aria-selected="false">{t('Experience')}</Link> : ''}

                                            {/* <Link class="nav-item nav-link tabsDoctor" id="nav-tab4" data-bs-toggle="tab" data-bs-target="#tab1-2" type="button" role="tab" aria-selected="false">Certifications</Link> */}
                                        </div>
                                    </nav>
                                    {/* <!-- Tab panes --> */}
                                    <div class="tab-content" id="nav-tabContent">
                                        <div role="tabpanel" class="tab-pane fade show active" id="tab1-1">
                                            {/* <h4>Endocrinology</h4> */}
                                            {doctorDetails.services ? <p class="line-h-2 lead text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.services }}></p> : ''}
                                            {/* <p class="mb-0 text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.experiences }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p> */}
                                        </div>
                                        <div role="tabpanel" class="tab-pane fade show active" id="tab1-2">
                                            {/* <h4>Endocrinology</h4> */}
                                            {doctorDetails.qualifications ? <p class="line-h-2 lead text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.qualifications }}></p> : t('No Data')}
                                            {/* <p class="mb-0 text-grey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p> */}
                                        </div>
                                        <div role="tabpanel" class="tab-pane fade" id="tab1-3">
                                            {/* <h4>Endocrinology</h4> */}
                                            {/* <p class="line-h-2 lead text-grey">Specialty of medicine; some would say a sub-specialty of internal medicine, which deals with the diagnosis and treatment of diseases related to hormones.</p> */}
                                            {doctorDetails.experiences ? <p class="line-h-2 lead text-grey" dangerouslySetInnerHTML={{ __html: doctorDetails.experiences }}></p> : t('No Data')}

                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-4 col-md-12 free-days">
                                <div class="dark-bg text-white p-lg-5 p-3 clearfix h-100">
                                    <h4 class="text-white text-capitalize fa-fade">Book Now</h4>
                                    <ul class="list-unstyled working-hours overflow-auto">
                                        {freeDaysMsg ? <h4 className='text-center pt-5 text-danger'>" {freeDaysMsg} !!!!!!! "</h4> : ''}
                                        {freeDays?.map(freeDay => <>
                                            <li className='appointment d-flex align-items-center justify-content-between cursor-pointer' onClick={() => handleOpenNextModal(freeDay.day)}><span><img className='pe-2' src="/images/pattern/booking.png" alt="" srcSet="" />{freeDay.day}</span> <span className='pe-1'> {moment(freeDay.first_free, 'HH:mm:ss').format('h:mm A')}</span> : <span>  {moment(freeDay.last_free, 'HH:mm:ss').format('h:mm A')} </span></li>
                                        </>)}
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </> : ''}
            {/* <!--team tab end-- > */}

        </div >

        {/* slots Modal */}
        <Modal className='modale fade' id='showAvailableSlotsModal' size="lg" scrollable show={showAvailableSlotsModal} onHide={handleCloseAvailableSlotsModal}>
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title id="contained-modal-title-vcenter"><span className='text-black'> {t('Available Slots')} </span> <span className='text-theme fw-bolder'> -  {doctorNameData}</span></Modal.Title>

            </Modal.Header>
            <Modal.Body>

                <div className="row justify-content-center calender-box text-center g-3">
                    {freeDaysMsg ? <h6> {freeDaysMsg}</h6> : ''}
                    {freeDays.length > 0 ?
                        freeDays?.map(freeDay => <>
                            <div className="col-md-3">
                                {/* <h4> {freeDaysMsg}</h4> */}
                                <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={() => handleOpenNextModal(freeDay.day)}>
                                    <div className=" appointment-box bg-mute rounded-0"><p className='m-0 text-black'>
                                        <span className='d-block text-theme'>{format(new Date(freeDay.day), 'EEEE')}</span>
                                        {freeDay.day}</p>
                                    </div>
                                    <div className="inner-box m-3" style={{ border: '1px solid #409639' }} >
                                        <div className="date px-2"><h6 className='m-0'>
                                            {/* <span className='pe-1'> {moment(freeDay.first_free, 'HH:mm:ss').format('h:mm A')}</span> : <span>  {moment(freeDay.last_free, 'HH:mm:ss').format('h:mm A')} </span> */}
                                            <span>{(parseInt(freeDay.first_free.split(':')[0], 10) >= 8 && parseInt(freeDay.last_free.split(':')[0], 10) <= 16) ? t('Morining') : (parseInt(freeDay.first_free.split(':')[0], 10) >= 16 && parseInt(freeDay.last_free.split(':')[0], 10) <= 23) ? t('Evening') : t('Morning,Evening')}</span>

                                        </h6></div>
                                        <div className="appointment-box theme-bg"><h6>{t('Book Now')}</h6></div>
                                    </div >
                                </div >

                            </div >
                        </>) : ''}

                    {/* {freeDays?.map(freeDay => <>
                        <div className="col-md-3">
                           
                            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={() => handleOpenNextModal(freeDay.day)}>
                                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>{freeDay.day}</h6>
                                </div>
                                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} >
                                    <div className="date px-1"><h4> <span className='pe-1'> {moment(freeDay.first_free, 'HH:mm:ss').format('h:mm A')}</span><span>  {moment(freeDay.last_free, 'HH:mm:ss').format('h:mm A')} </span></h4></div>
                                    <div className="appointment-box theme-bg"><h6>Book Now</h6></div>
                                </div >
                            </div >

                        </div >
                    </>)} */}

                    {/* <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }
                            } onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div > */}

                </div >


            </Modal.Body >
            <Modal.Footer className='justify-content-between align-items-center ps-2'>
                {freeDays ? <>
                    <div className="text-start">

                        <ul>
                            <li><span className='text-theme fw-bold'>{t('Morning Shifts')} :</span> {t('from')} 8 am {t('to')} 4 pm </li>
                            <li><span className='text-theme fw-bold'>{t('Evening Shifts')} :</span> {t('from')} 4 pm {t('to')} 11 pm </li>

                        </ul></div></> : ''}
                {/* <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseAvailableSlotsModal}>
                    Close
                </Button> */}
            </Modal.Footer>
        </Modal >

        {/* < !--body content end-- >  */}
        {/* Nex Modal*/}
        <Modal className='modale fade vertical-align-middle' id="showNextModal" centered scrollable show={showNextModal} onHide={handleCloseNextModal}>
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>

                    {t('Please Enter Your Data')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center text-center">
                    {pinError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {pinError}</div> : ''}
                    {pinForgetMsg ? <div className="portal-success text-white  my-2"> {pinForgetMsg}</div> : ''}

                    <form className="row justify-content-center text-center" onSubmit={preventDefault} >
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="pin" onChange={handleChange} className="form-control" placeholder={t("Pin Number")}
                                required="required" data-error="Valid Patient ID is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6 mt-xs-3">
                            <input id="form_id2" type="text" name="phone" className="form-control" onChange={handlePhoneNumChange} placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="col-sm-12 mt-1  mb-3">
                            <span className='small'><Link onClick={handleOpenForgetPinModal}>{t('Forget My Pin Number')} </Link></span>
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                            <button className="btn btn-theme btn-radius" onClick={checkPatientPhone}><span>{t('Confirmed')}</span>
                            </button>
                        </div>

                        <div className="col-md-8">
                            <button className="btn btn-theme btn-radius w-100" onClick={handleOpenFirstTimeModal}><span>{t('This Is My First Time')}</span>
                            </button>
                        </div>
                    </form>

                </div>

            </Modal.Body>
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseNextModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >

        {/* free slots  */}

        < Modal className='modale fade' size="lg" scrollable id='showFreeSlotsModal' show={showFreeSlotsModal} onHide={handleCloseFreeSlotsModal} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title> <span className='text-black'> {t('Free Slots')}</span> <span className='text-theme fw-bolder'> - {doctorNameData}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center calender-box text-center gy-3">
                    {FreeSlotsMsg ? <h4>{FreeSlotsMsg}</h4> : <>
                        <div className="team-description text-center row flex-column align-items-center mb-3">
                            <h4 className='text-muted mt-2'>👋 {t('Hello')} {patienName}</h4>
                            <p className='mt-2 '> {t('Here is Your Available Slots and You can Change your choice for day')} </p>
                            <div className="col-md-5">

                                <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" >
                                    <i className="fa-regular fa-calendar text-main px-2 border-end" style={{ fontSize: '18px' }}></i>

                                    <Select options={freeDaysOptions} placeholder={date} value={dateValue} className="w-100 ps-3" onChange={handleSelectedDate} styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                            outline: state.isFocused ? ' none' : 'none',
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                            backgroundColor: state.isFocused ? '#409639' : ' ',
                                            color: state.isFocused ? '#fff' : '',

                                        }),
                                    }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                    </Select>
                                </div>
                            </div>
                        </div>
                    </>}
                    {/* <div className="team-description text-center row flex-column align-items-center mb-3">
                        <h4 className='text-muted mt-2'>👋 Hello {patienName}</h4>
                        <p className='mt-2 '> Here is Your Available Slots and You can Change your choice for day </p>
                        <div className="col-md-5">

                            <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" >
                                <i className="fa-regular fa-calendar text-main px-2 border-end" style={{ fontSize: '18px' }}></i>

                                <Select options={freeDaysOptions} placeholder={date} value={dateValue} className="w-100 ps-3" onChange={handleSelectedDate} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                        outline: state.isFocused ? ' none' : 'none',
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                        backgroundColor: state.isFocused ? '#409639' : ' ',
                                        color: state.isFocused ? '#fff' : '',

                                    }),
                                }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                </Select>
                            </div>
                        </div>
                    </div> */}
                    {freeSlots?.map((freeSlot, index) => <>
                        <div className="col-md-3" key={index}>
                            {/* {freeDaysMsg ? <h4>{freeDaysMsg}</h4> : ''} */}
                            <div className="inner-box" style={{ width: '100%', border: '1px solid #409639' }} onClick={() => handleOpenConfirmedModal(freeSlot.time, freeSlot.time_to)} >

                                <div className="date px-1"><h4> <span>{moment(freeSlot.time, 'HH:mm:ss').format('h:mm A')} </span> : <span>{moment(freeSlot.time_to, 'HH:mm:ss').format('h:mm A')}</span></h4></div>
                                <div className="appointment-box theme-bg"><h6>Book Now</h6></div>
                            </div >

                        </div >

                    </>
                    )}





                </div>

            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseFreeSlotsModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
        {/* confirmed Modal */}
        < Modal className='modale fade vertical-align-middle' scrollable id='showConfirmedModal' show={showConfirmedModal} onHide={handleCloseConfirmedModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>                    Your Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center my-5">
                    <div className="team-description text-center">
                        <Link to='' className='h3'>{bookingMsg}</Link>
                        {/* {bookingMsg ? <p className='mt-5 '> If you need to change or cancel your booking, please call 16259.</p> : ''} */}

                    </div>

                </div>

            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseConfirmedModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >

        {/* forget Pin MODAL*/}
        < Modal className='modale fade vertical-align-middle' centered id='showForgetPinModal' show={showForgetPinModal} onHide={handleCloseForgetPinModal} >
            <Modal.Header closeButton>
                <img src='../../images/cropped-9952-img-32x32.jpg' alt="sgh Icon" />

                <Modal.Title>                   Forget Your Pin
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {pinForgetMsgError ? <div className="portal-error text-white  my-2">ERROR! {pinForgetMsgError}</div> : ''}
                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={forgetPinNumber} method="get" action="">
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input type="text" name="id" className="form-control" placeholder="Phone Number"
                                required="required" data-error="Valid Phone Number is required." value={userPhoneInp} onChange={(e) => setuserPhoneInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6">
                            <input type="number" name="id" className="form-control" placeholder="National ID"
                                required="required" data-error="Valid National ID is required." minLength={14} maxLength={14} value={userIdInp} onChange={(e) => setUserIdInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>Confirmed</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseForgetPinModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >

        {/* my first time MODAL*/}
        <Modal className='modale fade vertical-align-middle' centered id='showFirstTimeModal' show={showFirstTimeModal} onHide={handleCloseFirstTimeModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>   Enter Your Data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {patientRegistrationError ? <div className="portal-error text-white  my-2">ERROR! {patientRegistrationError}</div> : ''}
                    <form className="row justify-content-center text-center" onSubmit={registerNewPatient} method='post'>
                        <div className="messages"></div>
                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="Fullname" value={patientName} onChange={(e) => setPatientName(e.target.value)} className="form-control" placeholder="Full Name"
                                required="required" data-error="Valid Full Name is required." />
                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <select className="form-select form-control" required="required" value={patientGender} onChange={(e) => setPatientGender(e.target.value)} data-error="Valid Gender is required.">
                                <option value="- Select Gender">- Select Gender</option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                            </select>

                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <input id="form_number1" type="text" name="id" className="form-control" value={patientPhone} onChange={(e) => setPatientPhone(e.target.value)} placeholder="Phone Number"
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="form-group col-sm-6">
                            <input type="date" className="form-control" value={patientBirthDate} onChange={(e) => setPatientBirthDate(e.target.value)} placeholder="Date" id="datepicker2" />
                        </div>
                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>Register</span>
                            </button>
                        </div>


                    </form>
                </div >

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseFirstTimeModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >
        {/* OTP MODAL */}

        < Modal className='modale fade vertical-align-middle' centered id='showOTPModal' show={showOTPModal} onHide={handleCloseOTPModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>   Enter Your OTP
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {patientCodeError ? <div className="portal-error text-white  my-2">ERROR! {patientCodeError}</div> : ''}
                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={checkNewPatientCode} method="post">
                        <div className="messages"></div>


                        <div className="form-group col-sm-6">
                            <input type="text" name="id" className="form-control" placeholder="Code"
                                required="required" data-error="Valid Code is required." onChange={(e) => setPatientCode(e.target.value)} value={patientCode} />
                            <div className="help-block with-errors"></div>
                        </div>


                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>Confirmed</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseOTPModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >
    </>
}
