import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { Link, useLocation, useParams } from 'react-router-dom'
import PageTitle from '../../../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-js-pagination';
import { format, isValid, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';
export default function FacilitiesEvents() {

    // translation 
    const { t } = useTranslation("CairoEvent");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();

    // const history = useHistory();
    const [eventModal, setEventModal] = useState(false);
    const eventModalClose = () => {
        setEventModal(false);
    }
    function openModal(event_id) {
        setEventId(event_id);
        setEventModal(true);
    }

    const [showForm, setShowForm] = useState(false);

    const handleFormClose = () => setShowForm(false);
    const handleFormShow = () => setShowForm(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };



    // submit registartion
    const [submitMsg, setSubmitMsg] = useState('');
    const [fullName, setFullName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [eventId, setEventId] = useState('');
    const [email, setEmail] = useState('');

    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();

        bodyFormData.append('fullName', fullName);
        bodyFormData.append('PhoneNumber', phoneNum);
        bodyFormData.append('email', email);
        bodyFormData.append('eventId', eventId);

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/cairoEvents`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);
                    if (response.data.Edata.request_id > 0) {
                        eventModalClose();
                        handleFormShow()
                        setSubmitMsg(t('Thank you for your submition!'))
                    } else {
                        handleFormClose();
                        openModal();
                        setSubmitMsg('Error!! please Check Your Email ')

                    }

                    // handleFormShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // pagination
    let [lastPageOfSearch, setLastPageOfSearch] = useState()

    let [perPage, setPerPage] = useState();
    let [total, setTotal] = useState();
    let [currentPage, setCurrentPage] = useState(1)
    const handlePageChange = (pageNumber) => {
        // const pageNumber = e;
        setCurrentPage(pageNumber);
        scrollToTop();
    };

    const [allEvents, SetAllEvents] = useState([]);
    async function getEvents(hospital_id) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/event/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&page=${currentPage}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    SetAllEvents(response.data.Edata.data);
                    setPerPage(response.data.Edata.per_page);
                    setTotal(response.data.Edata.total);
                    setLastPageOfSearch(response.data.Edata.last_page)
                    // console.log('all services ' + response.data.Edata.data);


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getEvents('1');
    }, [currentPage])


    return <>

        <div className="page-content">

            {/* <!--blog start--> */}
            {/* <!--page title start--> */}
            {i18n.language === 'en' ? <PageTitle title={t('Facilities')} anotherTitle={t('Event')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/facilities-events`} currentPage={t('Facilities Event')} /> : <PageTitle title={t('Facilities Event')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/facilities-events`} currentPage={t('Facilities Event')} />}
            {/* <!--page title end--> */}
            <section className='pt-2'>

                <div className="container" >

                    <div className="row mt-5 gx-3 gy-3 justify-content-start" >
                        {allEvents.map((event, index) => <>
                            <div className="col-md-4" key={index}>
                                <div class="service-item event-item p-0 ">

                                    {/* <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/event-details/${event.title}/${event.id}`}> */}
                                    <div class="service-images  position-relative">
                                        {event.image ? <img class="img-fluid" src={event.image} alt="" /> : <img class="img-fluid" src="/images/events/coming-soon-_result.webp" alt="" />}

                                        <div class="service-icon service-event text-muted" >
                                            <i class="text-muted fas fa-calendar-alt text-theme bg-transparent fs-6 pe-1" aria-hidden="true"></i>

                                            {isValid(parseISO(event.start_date)) ? (format(new Date(event.start_date), "do MMM")).replace(/(\d+)(st|nd|rd|th)/, '$1') : ' '}

                                        </div>
                                        <div className="btn-service justify-content-center align-items-center position-absolute">
                                            <button className='btn btn-theme btn-sm appoint-btn mt-sm-0 consultation rounded-top rounded-bottom' onClick={() => { openModal(event.id) }}>{t('Registration')}</button>

                                        </div>
                                    </div>
                                    <h4 className='bg-theme' style={{ zIndex: 1001 }}>{event.title}</h4>
                                    <div class="service-description text-black">
                                        <p dangerouslySetInnerHTML={{ __html: event.shortnote }}></p> <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/event-details/${event.title}/${event.id}`}>{t('Details')}
                                            {i18n.language === 'en' ? <i class="ms-2 fas fa-long-arrow-alt-right"></i> : <i class="ms-2 fas fa-long-arrow-alt-left"></i>}
                                        </Link>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </>)}


                    </div>

                    {lastPageOfSearch > 1 ? <>
                        < div className="row justify-content-center text-center mt-3" id='pagination'>
                            < Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideLastPages={true}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div></> : ''}
                </div>
            </section>
        </div>


        <Modal show={eventModal} onHide={eventModalClose} id="patienteventModal" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Registration')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >


                <Form onSubmit={submitForm} className='row justify-content-center align-items-end flex-column'>
                    <Form.Group
                        className=" col-md-6 mx-auto ms-3 mb-5"
                        controlId="ControlText"
                    >
                        <Form.Control type="text" name="name" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder={t("Full Name")}
                        />
                        <Form.Control type="number" name="phone" value={phoneNum} onChange={(e) => setPhoneNum(e.target.value)} placeholder={t("Phone Number")}
                        />
                        <Form.Control type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("Email")}
                        />
                    </Form.Group>
                    {/* {pinError ? <small className='text-danger mt-3 col-md-8 mx-auto text-center mb-3'>{pinError}</small> : ''} */}

                    <Button className='col-md-3 me-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit" >
                        {t('Register')}
                    </Button>


                </Form>



            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={eventModalClose}>
                    Close
                </Button>
              
            </Modal.Footer> */}
        </Modal>

        <Modal
            size="lg"
            backdrop="static"
            style={customStyles}
            aria-labelledby="contained-modal-title-vcenter"
            centered show={showForm} onHide={handleFormClose} className="top-0 ">

            <Modal.Header closeButton>
                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Confirmation Message')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="team-member text-center">
                    <div className="team-description pt-0">
                        <h5 className="my-5"><Link>{submitMsg}</Link></h5>

                    </div>



                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleFormClose}>
                    {t('Close')}
                </Button>

            </Modal.Footer>

        </Modal>
    </>
}