import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';
export default function SpecialityDetails() {
    // translation 
    const { t } = useTranslation("CairoSpecialistsDetails");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    const [expandedItems, setExpandedItems] = useState([]);
    const { speciality_id, speciality_name } = useParams();

    const data = [
        {
            id: 1,
            title: 'Plan pre-operative care and explain proposed anesthetic technique',

        },
        {
            id: 2,
            title: 'Post-operative pain management',

        },
        {
            id: 3,
            title: 'Painless labor (epidural)',

        },
        {
            id: 4,
            title: 'Anesthesia for pediatrics and neonates',

        },
        {
            id: 5,
            title: 'Anesthesia for day case surgeries',

        },
        {
            id: 6,
            title: 'Anesthesia outside the OT (e.g. patients undergoing diagnostic MRI GI endoscopy)',

        },
        {
            id: 7,
            title: 'Anesthesia for cardiac surgery',

        },
        {
            id: 8,
            title: 'Transplant Anesthesia',

        },
    ];

    const toggleList = (itemId) => {
        if (expandedItems.includes(itemId)) {
            setExpandedItems(expandedItems.filter((id) => id !== itemId));
        } else {
            setExpandedItems([...expandedItems, itemId]);
        }
    };
    const renderArrowIcon = (itemId) => {
        if (expandedItems.includes(itemId)) {
            return (


                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 14l5-5 5 5z" />
                </svg>
            );
        } else {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 10l5 5 5-5z" />
                </svg>
            );
        }
    };

    const renderList = (items) => {
        return items.map((item) => (
            <li key={item.id}>
                <div className="item" onClick={() => toggleList(item.id)}>
                    {renderArrowIcon(item.id)}
                    <span
                        style={{
                            fontWeight: expandedItems.includes(item.id) ? 'bold' : 'normal',
                            color: expandedItems.includes(item.id) ? '#4ab242' : 'black',
                        }}
                    >
                        {item.title}
                    </span>
                </div>
                {expandedItems.includes(item.id) && item.children && (
                    <ul className="sub-list">{renderList(item.children)}</ul>
                )}
            </li>
        ));
    };

    const [allSpecialitiesDetails, SetAllSpecialitiesDetails] = useState({});
    const [allSpecialitiesDoctors, SetAllSpecialitiesDoctors] = useState([]);
    async function getSpecialitiesDetails(hospital_id, specislityId) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/department/details/${hospital_id}/${specislityId}/${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    SetAllSpecialitiesDetails(response.data.Edata.main);
                    SetAllSpecialitiesDoctors(response.data.Edata.Doctors);

                    // SetAllServicesDetailsImages(response.data.Edata.images);
                    // allServicesDetails.images?.map((imgService) => console.log(imgService))
                    console.log('all spec details ' + response.data.Edata.main);
                    // console.log(allServicesDetailsImages);

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getSpecialitiesDetails('1', speciality_id);

    }, [])

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={allSpecialitiesDetails.famous_name} anotherTitle={t('Department')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties`} prevPage={t('specialties')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/${allSpecialitiesDetails.famous_name}/${allSpecialitiesDetails.id}`} currentPage={allSpecialitiesDetails.famous_name} /> : <PageTitle title={t('Department')} anotherTitle={allSpecialitiesDetails.famous_name} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties`} prevPage={t('specialties')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/${allSpecialitiesDetails.famous_name}/${allSpecialitiesDetails.id}`} currentPage={allSpecialitiesDetails.famous_name} />}
        {/* <!--page title end--> */}
        <div class="page-content">

            {/* <!--service details start--> */}


            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 order-2 col-md-12">
                            <div class="left-side">
                                <div>

                                    <div class="service-details">
                                        <h2 className="title "> <span className='fw-bold'>{t('Overview')}</span>
                                        </h2>
                                        <p class="lead" dangerouslySetInnerHTML={{ __html: allSpecialitiesDetails.brief }}></p>
                                        {/* <p class="mb-0">The provision of state-of-the-art anesthesia care is a critical component of modern surgical medicine. In Saudi German Hospital, proficient anesthesia doctors are available across a wide range of surgical specialties, including cardiac, thoracic, general surgery, neurosurgery, ENT, vascular, bariatric, colorectal, urology, gynecology, oncology, ophthalmology, orthopedics, and sports medicine services.</p> */}
                                        {allSpecialitiesDetails.services ? <>
                                            <div class="tab mt-3">
                                                {/* <!-- Nav tabs --> */}
                                                <div class="service-details">

                                                    <h6 className='h3 text-white'><span className='theme-bg p-2 rounded-1'> {t('Scope Of Services')} </span></h6>
                                                </div>
                                                {/* <nav>
                                                <div class="nav nav-tabs" id="nav-tab" role="tablist"> 
                                                <Link class="nav-item nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#tab1-1" type="button" role="tab" aria-selected="true">Scope of Service</Link>
                                                    <Link class="nav-item nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#tab1-2" type="button" role="tab" aria-selected="false">Treatments</Link>
                                                </div>
                                            </nav> */}
                                                {/* <!-- Tab panes --> */}
                                                <div className="footer-list footer-list-services" dangerouslySetInnerHTML={{ __html: allSpecialitiesDetails.services }} >


                                                    {/* <div role="tabpanel" class="tab-pane fade show active" id="tab1-1">

                                                    <ul className="main-list list-unstyled pricing-list clearfix p-0">{renderList(data)}</ul>

                                                </div> */}

                                                </div>
                                            </div>
                                        </> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 order-1 order-md-3  col-md-12 mt-5 mt-lg-0">
                            <div class="widget mb-5">
                                <div class="service-images">
                                    {allSpecialitiesDetails.image ? <img class="img-fluid w-100" src={allSpecialitiesDetails.image} alt="" /> : <img class="img-fluid w-100" src="/images/cairo/specialitites/Specialties-for-all---min.webp" alt="" />}
                                </div>
                            </div>
                            {/* <div class="widget mb-5 dark-bg px-3 py-3 text-white">
                                <ul class="list-unstyled working-hours clearfix">
                                    <li><span>Monday - Friday</span> 8:00 to 17:00</li>
                                    <li><span>Saturday</span> 9:00 to 18:00</li>
                                    <li><span>Sunday</span> 9:00 to 13:00</li>
                                </ul>
                            </div> */}
                            {/* <div class="widget grey-bg px-3 py-3">
                                <div class="owl-carousel no-pb" data-items="1" data-dots="false" data-autoplay="true">
                                    <div class="item">
                                        <div class="testimonial m-0">
                                            <div class="testimonial-content"> <span><i class="fas fa-quote-left"></i></span>
                                                <p>“ Totam mollitia incidunt Consectetur adipisicing elit, vero cupiditate obcaecati iusto tempora unde! Numquam officiis, quae adipisci quam laudantium nulla modi, adipisci quam laudantium nulla modi vero cupiditate. ”</p>
                                                <div class="testimonial-caption">
                                                    <h6>Kendra Velly</h6>
                                                    <label>- Manager</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="testimonial m-0">
                                            <div class="testimonial-content"> <span><i class="fas fa-quote-left"></i></span>
                                                <p>“ Consectetur adipisicing elit, Totam mollitia incidunt vero cupiditate obcaecati iusto tempora unde! Numquam officiis, quae adipisci quam laudantium nulla modi, adipisci quam laudantium nulla modi. ”</p>
                                                <div class="testimonial-caption">
                                                    <h6>Samantha Lion</h6>
                                                    <label>- Manager</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section >

            {/* <!--service details end--> */}





            {/* <!--team start--> */}

            {allSpecialitiesDoctors.length > 0 ? <>
                <section>
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                                <div class="section-title">
                                    {/* <h6>Team</h6> */}
                                    {i18n.language === 'en' ? <h2 class="title">{t('Related')} <span>{t('Doctors')}</span></h2> : <h2 class="title">{t('Doctors')} <span>{t('Related')}</span></h2>}
                                    {/* <p class="mb-0">Saudi German Hospital Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p> */}
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            {allSpecialitiesDoctors.map((specialitiesDoctor, index) => <>
                                <div class="col-lg-4 col-md-12 mt-5" key={index}>
                                    <div class="team-member text-center">
                                        <div class="team-images">

                                            {specialitiesDoctor.image ? <img class="img-fluid" src={specialitiesDoctor.image} alt={specialitiesDoctor.famous_name} /> : specialitiesDoctor.Sex === 1 ? <img class="img-fluid" src="/images/team/doctor-female.png" alt="" /> : <img class="img-fluid" src="/images/team/doctor-male.png" alt="" />}
                                            <div class="team-about">
                                                <p dangerouslySetInnerHTML={{ __html: specialitiesDoctor.pio && specialitiesDoctor.pio.length > 100 ? specialitiesDoctor.pio.substring(0, 100) : specialitiesDoctor.pio }}></p>
                                                <div class="team-social-icon">
                                                    <button className='btn bg-white  btn-sm appoint-btn mt-sm-0 mt-3 rounded-top rounded-bottom'><Link onClick={scrollToTop} className='text-muted' to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/${specialitiesDoctor.doc_id}`}>{t('Full Profile')}</Link>  </button>
                                                    {/* <button className='btn bg-white  btn-sm appoint-btn mt-sm-0 mt-3 rounded-top rounded-bottom'><Link onClick={() => handleOpenAvailableSlotsModal(doctorSearch.doc_id, doctorSearch.department_id, doctorSearch.branch, doctorSearch.famous_name)} className='text-muted' >Book Now</Link>  </button> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div class="team-description"> <span>{t('Doctor')}</span>
                                            <h5><Link to="doctor-single.html">{specialitiesDoctor.famous_name}</Link></h5>
                                            <span className='text-muted date'> {specialitiesDoctor.department_name}</span>

                                        </div>
                                        {/* <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/dr-name`} class="btn btn-sm btn-border btn-theme w-100 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>Personal Page</Link> */}
                                    </div>
                                </div>
                            </>)}

                        </div>
                    </div>
                </section>
            </> : ''}


            {/* <!--team end--> */}

        </div >
    </>
}
