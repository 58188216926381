import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { LocalizationContext } from '../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';

export default function PatientSatisfactionQuestionnaire(props) {
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const { t } = useTranslation("PatientSatisfactionQuestionnaire");
    const { pathname } = useLocation();
    const navigate = useNavigate();


    const [department, setDepartment] = useState('');
    const [phone, setPhone] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [question6, setQuestion6] = useState('');
    const [question7, setQuestion7] = useState('');
    const [question8, setQuestion8] = useState('');
    const [question9, setQuestion9] = useState('');
    const [question10, setQuestion10] = useState('');
    const [question11, setQuestion11] = useState('');
    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    // const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    const validateForm = () => {
        const errors = [];
        const phoneRegex = /^(01)[0-2,5]{1}[0-9]{8}$/;
        if (!department) {
            errors.push(t('Kindly choose a Department.'));
        }
        if (!phone) {
            errors.push(t('Please provide your Phone Number.'));
        }
        else if (!phoneRegex.test(phone)) {
            errors.push(t('Please enter a valid phone number.'));

        }
        if (!question1) {
            errors.push(t('Kindly choose your level of satisfaction with the speed of starting the service.'));
        }
        if (!question2) {
            errors.push(t('Kindly choose your level of satisfaction with the waiting time to start your session.'));
        }
        if (!question3) {
            errors.push(t('Kindly choose your level of satisfaction with the treating doctor.'));
        }
        if (!question4) {
            errors.push(t('Kindly choose your level of satisfaction with the nursing service.'));
        }
        if (!question5) {
            errors.push(t('Kindly choose your level of satisfaction with the cleanliness inside the unit.'));
        }
        if (!question6) {
            errors.push(t('Kindly choose your level of satisfaction with the concept of privacy respect inside the unit.'));
        }
        if (!question7) {
            errors.push(t('Kindly choose your level of satisfaction with the follow-up instructions about caring for yourself at home.'));
        }
        if (!question8) {
            errors.push(t('Kindly choose your level of satisfaction with the infection control practice inside the unit.'));
        }
        if (!question9) {
            errors.push(t('Would you recommend the department inside Saudi-German Hospital Cairo to your family and friends?'));
        }
        if (!question10) {
            errors.push(t('Please provide your recommendations and suggestions to improve service in the unit.'));
        }
        if (!question11) {
            errors.push(t('Please provide your recommendations'));
        }

        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();

            bodyFormData.append('Department', department);
            bodyFormData.append('Phone Number', phone);
            bodyFormData.append('How satisfied are you with the speed of starting service?', question1);
            bodyFormData.append('How satisfied are you with the waiting time to start your Session?', question2);
            bodyFormData.append('How satisfied are you with the treating doctor?', question3);
            bodyFormData.append('How satisfied are you with the nursing service?', question4);
            bodyFormData.append('How satisfied are you with the Cleanliness inside the unit?', question5);
            bodyFormData.append('How satisfied are you with the concept of privacy respect inside the unit?', question6);
            bodyFormData.append('How satisfied are you with the follow up instructions about caring for yourself at home (e.g., taking medications, getting follow-up medical care)?', question7);
            bodyFormData.append('How satisfied are you with infection control practice inside the unit?', question8);
            bodyFormData.append('Would you recommend the department inside Saudi – German hospital Cairo to your family and friends?', question9);
            bodyFormData.append('What is your recommendations/Suggestions to improve service in the unit?', question10);
            bodyFormData.append('Do you recommend to add a new service or redesign for the services? If so , kindly mention them.', question11);

            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/PatientSatisfactionQuestionnaire`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('Thank you for Registration!'));
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                        setDepartment('');
                        setPhone('');
                        setQuestion1('');
                        setQuestion2('');
                        setQuestion3('');
                        setQuestion4('');
                        setQuestion5('');
                        setQuestion6('');
                        setQuestion7('');
                        setQuestion8('');
                        setQuestion9('');
                        setQuestion10('');
                        setQuestion11('');

                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        {/* <PageTitle title={t('Patient Satisfaction Questionnaire')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-satisfaction-questionnaire`} currentPage={t('Patient Satisfaction Questionnaire')} /> */}
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='p-0'>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center  ">
                                        <div className="col-md-12 text-center p-3" style={{ background: 'linear-gradient(135deg, #bfd7d8, #97bac5)' }}>
                                            <img src="/images/forms/Patient-Satisfaction-Survey_1.webp" className='img-fluid' alt="open day" />
                                        </div>
                                        <div className="col-md-12 my-4">
                                            <center>
                                                <div class="switch">
                                                    <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox" onClick={() => {
                                                        if (i18n.language === 'en') {
                                                            handleLanguageChange('ar');
                                                            document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');

                                                            document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');
                                                            navigate(`/${Cookies.get('i18next')}/${pathname.slice(4)}`)
                                                        } else {
                                                            handleLanguageChange('en');
                                                            document.getElementsByTagName("html")[0].setAttribute("lang", 'en');

                                                            document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');

                                                            navigate(`/${Cookies.get('i18next')}/${pathname.slice(4)}`);
                                                        }
                                                    }} />
                                                    <label for="language-toggle"></label>
                                                    <span className={i18n.language === 'en' ? 'on' : 'off'}> English</span>
                                                    <span className={i18n.language === 'ar' ? 'on' : 'off'}>  العربية</span>
                                                </div>
                                            </center>
                                        </div>
                                        <div className="col-md-10 p-5 order-3 ">
                                            <div className='row justify-content-center align-items-center'>
                                                {/* <div class="section-title mb-0">
                                                    <h2 class="title mb-3 text-center">complete <span>Form</span></h2>

                                                </div> */}
                                                <div class="form-group col-md-2">
                                                    <h6 className='fw-bolder fs-6 mb-0'>{t('Select Department')}</h6>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <select className='fs-6 w-100' onChange={(e) => setDepartment(e.target.value)} value={department}>
                                                        <option value="">{t('Select Department')}</option>
                                                        <option value={t("IN-Patient")}>{t('IN-Patient')}</option>
                                                        <option value={t("Delivery Room")}>{t('Delivery Room')}</option>
                                                        <option value={t('Hemodialysis')}>{t('Hemodialysis')}</option>
                                                        <option value={t('oncology')}>{t('oncology')}</option>
                                                        <option value={t('Radiotherapy')}>{t('Radiotherapy')}</option>
                                                        <option value={t('physiotherapy')}>{t('physiotherapy')}</option>
                                                        <option value={t('Transplantation')}>{t('Transplantation')}</option>
                                                        <option value={t('Emergency')}>{t('Emergency')}</option>
                                                        <option value={t('OPD')}>{t('OPD')}</option>
                                                        <option value={t('Pharmacy')}>{t('Pharmacy')}</option>
                                                        <option value={t('ICU')}>{t('ICU')}</option>
                                                        <option value={t('Radiology')}>{t('Radiology')}</option>
                                                        <option value={t('Laboratory')}>{t('Laboratory')}</option>
                                                        <option value={t('Cardiology')}>{t('Cardiology')}</option>
                                                        <option value={t('Bone Marrow')}>{t('Bone Marrow')}</option>
                                                        <option value={t('Blood Bank')}>{t('Blood Bank')}</option>
                                                        <option value={t('Other')}>{t('Other')}</option>

                                                    </select><br />
                                                    {errors.includes(t('Kindly choose a Department.')) && (
                                                        <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose a Department.')}</div>
                                                        </>)}
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label class="form-label fs-6 fw-bolder">{t('phone Number')}</label>

                                                </div>
                                                <div className="form-group col-md-4">
                                                    <input className={`form-registration mb-0 ${errors.includes(t('Please provide your Phone Number.')) ? 'is-invalid' : ''}${errors.includes(t('Please enter a valid phone number.')) ? 'is-invalid' : ''}`} value={phone} onChange={(e) => setPhone(e.target.value)} data-error={t("Please provide your Phone Number.")} type="text" />
                                                    {errors.includes(t('Please provide your Phone Number.')) && <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Please provide your Phone Number.')}</div></>}
                                                    {errors.includes(t('Please enter a valid phone number.')) && (<><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i>
                                                        <div className="ivaild-error d-inline ms-2">{t('Please enter a valid phone number.')}</div></>
                                                    )}
                                                </div>
                                                {/* <div class="form-group col-md-12">

                                                    {errors.includes(t('Kindly choose a Department.')) && (
                                                        <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose a Department.')}</div>
                                                        </>)}</div> */}
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the speed of starting the service.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with the speed of starting service?')}</label>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques1'>

                                                        <input
                                                            type="radio" className='me-2' id='ques1'
                                                            value='0'
                                                            checked={question1 === '0'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('Very bad')}
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques2'>

                                                        <input className='me-2' id='ques2'
                                                            type="radio"
                                                            value='25'
                                                            checked={question1 === '25'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('Bad')}
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques3'>

                                                        <input className='me-2' id='ques3'
                                                            type="radio"
                                                            value='50'
                                                            checked={question1 === '50'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('Fair')}
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques4'>

                                                        <input className='me-2' id='ques4'
                                                            type="radio"
                                                            value='75'
                                                            checked={question1 === '75'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('Good')}
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques5'>

                                                        <input className='me-2' id='ques5'
                                                            type="radio"
                                                            value='100'
                                                            checked={question1 === '100'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('Very Good')}
                                                    </label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the speed of starting the service.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the speed of starting the service.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the waiting time to start your session.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with the waiting time to start your Session?')}</label>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques6'>
                                                        <input
                                                            type="radio" className='me-2' id='ques6'
                                                            value='0'
                                                            checked={question2 === '0'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques7'>
                                                        <input className='me-2' id='ques7'
                                                            type="radio"
                                                            value='25'
                                                            checked={question2 === '25'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />{t('Bad')}
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques8'>
                                                        <input className='me-2' id='ques8'
                                                            type="radio"
                                                            value='50'
                                                            checked={question2 === '50'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />{t('Fair')}
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques9'>
                                                        <input className='me-2' id='ques9'
                                                            type="radio"
                                                            value='75'
                                                            checked={question2 === '75'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />{t('Good')}
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques10'>
                                                        <input className='me-2' id='ques10'
                                                            type="radio"
                                                            value='100'
                                                            checked={question2 === '100'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the waiting time to start your session.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the waiting time to start your session.')}</div>
                                                            </>)}</div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the treating doctor.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with the treating doctor?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques11'>
                                                        <input
                                                            type="radio" className='me-2' id='ques11'
                                                            value='0'
                                                            checked={question3 === '0'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques12'>
                                                        <input className='me-2' id='ques12'
                                                            type="radio"
                                                            value='25'
                                                            checked={question3 === '25'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('Bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques13'>
                                                        <input className='me-2' id='ques13'
                                                            type="radio"
                                                            value='50'
                                                            checked={question3 === '50'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('Fair')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques14'>
                                                        <input className='me-2' id='ques14'
                                                            type="radio"
                                                            value='75'
                                                            checked={question3 === '75'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('Good')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques15'>
                                                        <input className='me-2' id='ques15'
                                                            type="radio"
                                                            value='100'
                                                            checked={question3 === '100'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the treating doctor.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the treating doctor.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the nursing service.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with the nursing service?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques16'>
                                                        <input
                                                            type="radio" className='me-2' id='ques16'
                                                            value='0'
                                                            checked={question4 === '0'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques17'>
                                                        <input className='me-2' id='ques17'
                                                            type="radio"
                                                            value='25'
                                                            checked={question4 === '25'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques18'>
                                                        <input className='me-2' id='ques18'
                                                            type="radio"
                                                            value='50'
                                                            checked={question4 === '50'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Fair')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques19'>
                                                        <input className='me-2' id='ques19'
                                                            type="radio"
                                                            value='75'
                                                            checked={question4 === '75'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Good')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques20'>
                                                        <input className='me-2' id='ques20'
                                                            type="radio"
                                                            value='100'
                                                            checked={question4 === '100'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the nursing service.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the nursing service.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the cleanliness inside the unit.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with the Cleanliness inside the unit?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques21'>
                                                        <input
                                                            type="radio" className='me-2' id='ques21'
                                                            value='0'
                                                            checked={question5 === '0'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques22'>
                                                        <input className='me-2' id='ques22'
                                                            type="radio"
                                                            value='25'
                                                            checked={question5 === '25'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques23'>
                                                        <input className='me-2' id='ques23'
                                                            type="radio"
                                                            value='50'
                                                            checked={question5 === '50'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Fair')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques24'>
                                                        <input className='me-2' id='ques24'
                                                            type="radio"
                                                            value='75'
                                                            checked={question5 === '75'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Good')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques25'>
                                                        <input className='me-2' id='ques25'
                                                            type="radio"
                                                            value='100'
                                                            checked={question5 === '100'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the cleanliness inside the unit.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the cleanliness inside the unit.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the concept of privacy respect inside the unit.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with the concept of privacy respect inside the unit?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques26'>
                                                        <input
                                                            type="radio" className='me-2' id='ques26'
                                                            value='0'
                                                            checked={question6 === '0'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques27'>
                                                        <input className='me-2' id='ques27'
                                                            type="radio"
                                                            value='25'
                                                            checked={question6 === '25'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques28'>
                                                        <input className='me-2' id='ques28'
                                                            type="radio"
                                                            value='50'
                                                            checked={question6 === '50'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Fair')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques29'>
                                                        <input className='me-2' id='ques29'
                                                            type="radio"
                                                            value='75'
                                                            checked={question6 === '75'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Good')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques30'>
                                                        <input className='me-2' id='ques30'
                                                            type="radio"
                                                            value='100'
                                                            checked={question6 === '100'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the concept of privacy respect inside the unit.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the concept of privacy respect inside the unit.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the follow-up instructions about caring for yourself at home.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with the follow up instructions about caring for yourself at home (e.g., taking medications, getting follow-up medical care)?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques31'>
                                                        <input
                                                            type="radio" className='me-2' id='ques31'
                                                            value='0'
                                                            checked={question7 === '0'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques32'>
                                                        <input className='me-2' id='ques32'
                                                            type="radio"
                                                            value='25'
                                                            checked={question7 === '25'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques33'>
                                                        <input className='me-2' id='ques33'
                                                            type="radio"
                                                            value='50'
                                                            checked={question7 === '50'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Fair')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques34'>
                                                        <input className='me-2' id='ques34'
                                                            type="radio"
                                                            value='75'
                                                            checked={question7 === '75'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Good')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques35'>
                                                        <input className='me-2' id='ques35'
                                                            type="radio"
                                                            value='100'
                                                            checked={question7 === '100'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the follow-up instructions about caring for yourself at home.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the follow-up instructions about caring for yourself at home.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Kindly choose your level of satisfaction with the infection control practice inside the unit.')) ? 'is-invalid' : ''}`}>{t('How satisfied are you with infection control practice inside the unit?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques36'>
                                                        <input
                                                            type="radio" className='me-2' id='ques36'
                                                            value='0'
                                                            checked={question8 === '0'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques37'>
                                                        <input className='me-2' id='ques37'
                                                            type="radio"
                                                            value='25'
                                                            checked={question8 === '25'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('Bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques38'>
                                                        <input className='me-2' id='ques38'
                                                            type="radio"
                                                            value='50'
                                                            checked={question8 === '50'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('Fair')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques39'>
                                                        <input className='me-2' id='ques39'
                                                            type="radio"
                                                            value='75'
                                                            checked={question8 === '75'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('Good')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques40'>
                                                        <input className='me-2' id='ques40'
                                                            type="radio"
                                                            value='100'
                                                            checked={question8 === '100'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Kindly choose your level of satisfaction with the infection control practice inside the unit.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose your level of satisfaction with the infection control practice inside the unit.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Would you recommend the department inside Saudi-German Hospital Cairo to your family and friends?')) ? 'is-invalid' : ''}`}>{t('Would you recommend the department inside Saudi – German hospital Cairo to your family and friends?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques41'>
                                                        <input
                                                            type="radio" className='me-2' id='ques41'
                                                            value='0'
                                                            checked={question9 === '0'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Very bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques42'>
                                                        <input className='me-2' id='ques42'
                                                            type="radio"
                                                            value='25'
                                                            checked={question9 === '25'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Bad')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques43'>
                                                        <input className='me-2' id='ques43'
                                                            type="radio"
                                                            value='50'
                                                            checked={question9 === '50'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Fair')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques44'>
                                                        <input className='me-2' id='ques44'
                                                            type="radio"
                                                            value='75'
                                                            checked={question9 === '75'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Good')}</label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques45'>
                                                        <input className='me-2' id='ques45'
                                                            type="radio"
                                                            value='100'
                                                            checked={question9 === '100'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Very Good')}</label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Would you recommend the department inside Saudi-German Hospital Cairo to your family and friends?')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Would you recommend the department inside Saudi-German Hospital Cairo to your family and friends?')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Please provide your recommendations and suggestions to improve service in the unit.')) ? 'is-invalid' : ''}`}>{t('What is your recommendations/Suggestions to improve service in the unit?')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <textarea class="form-control h-auto" value={question10} onChange={(e) => setQuestion10(e.target.value)}></textarea>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes(t('Please provide your recommendations and suggestions to improve service in the unit.')) && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Please provide your recommendations and suggestions to improve service in the unit.')}</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes(t('Please provide your recommendations')) ? 'is-invalid' : ''}`}>{t('Do you recommend to add a new service or redesign for the services? If so , kindly mention them.')} </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <div class="form-group col-md-12">
                                                        <textarea class="form-control h-auto" value={question11} onChange={(e) => setQuestion11(e.target.value)}></textarea>
                                                        <div class="form-group col-md-12 mb-0 mt-3">

                                                            {errors.includes(t('Please provide your recommendations')) && (
                                                                <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Please provide your recommendations')}</div>
                                                                </>)}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <button className="btn btn-border btn-circle w-100" type='submit'><span>{t('Submit')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-7 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/diabetes-health-awareness-1.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            <img src="/images/forms/diabetes-health-awareness-2.png" alt="patient survey 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                        </div> */}
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>{t('Your Message')}</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            {/* <p className='lead'>{messageForm}</p> */}
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        {t('Close')}
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
