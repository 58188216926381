import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LocalizationContext } from '../../Context/LocalizationContext'
import { useParams } from 'react-router-dom';
import { GetCategoryDetailsContext } from '../../Context/GetCategoryDetails';
import CopyWrite from '../Utilites/MayoClinic/CopyWrite';
import PageTitle from '../Utilites/PageTitle';
import PageDetails from '../Utilites/MayoClinic/PageDetails';
// import Table from 'react-bootstrap/Table';
// import Container from 'react-bootstrap/Container';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function RecipeDetails() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    let { id } = useParams();
    let { recipeDetails, setRecipeDetails, getCategoryDetails } = useContext(GetCategoryDetailsContext);
    console.log('kkkk');
    //   console.log(`${diseaseDetails}`);
    useEffect(() => {
        getCategoryDetails(id, setRecipeDetails);
    }, [i18n.language]);
    return <>

        {/* <!--page title start--> */}
        {recipeDetails.map((recipeDetail, index) => (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{recipeDetail.title}</title>
                <link rel="canonical" href={recipeDetail.originalUrl} />
            </Helmet>
            {i18n.language ? <PageTitle key={index} title={t('Mayo Clinic')} anotherTitle={t('Health Library')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Recipe/${recipeDetail.docId}`} currentPage={t('Recipes')} />
                : <PageTitle key={index} title={t('Health Library')} anotherTitle={t('Mayo Clinic')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Recipe/${recipeDetail.docId}`} currentPage={t('Recipes')} />}
        </>))}

        {/* <!--page title end--> */}

        <div class="page-content">

            {/* <!--blog start--> */}

            <section>
                <div class="container">
                    <div class="row">


                        {recipeDetails.map((recipeDetail, index) => (<>


                            <div key={index} class="col-lg-12 col-md-12 order-sm-12">
                                <PageDetails contentType={recipeDetail.contentType} title={recipeDetail.title} updateDate={recipeDetail.updateDate} />


                                <div className="service-details mt-4 row">

                                    {/* <h2 className="title"> {t('overview')} </h2> */}

                                    <div class="col-lg-9 col-md-12 order-sm-12">
                                        {/* <p className="lead" dangerouslySetInnerHTML={{ __html: testProcedureDetail.abstract }}></p> */}

                                        {/* {recipeDetail.Recipe.map((slide, index) => (<> */}

                                        <h3 >{t("Dietitian's Tip")}</h3>
                                        <p className='lead' dangerouslySetInnerHTML={{ __html: recipeDetail.Recipe.dietitianTip }}></p>
                                        <h3 >{t('ingredients')}</h3>

                                        <div className="mb-0" dangerouslySetInnerHTML={{ __html: recipeDetail.Recipe.ingredients }} >
                                        </div>

                                        <h3 > {t('Number of servings')}</h3>
                                        <p className='lead' dangerouslySetInnerHTML={{ __html: recipeDetail.Recipe.servingInfo }}></p>
                                        <h3 > {t('Directions')}</h3>

                                        <p className='lead' dangerouslySetInnerHTML={{ __html: recipeDetail.Recipe.directions }}></p>

                                    </div>


                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h3 >{recipeDetail.Recipe.nutrientAnalysis_label}</h3>

                                            <h6 className='fw-bolder'>{recipeDetail.Recipe.nutrientAnalysis.servingSize.label} : <span className='small text-muted fw-lighter' dangerouslySetInnerHTML={{ __html: recipeDetail.Recipe.servingSize }}></span>

                                            </h6>
                                            <div className="social-icons social-colored social-fullwidth">
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.nutrientAnalysis.calories.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Calories :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.calories.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                    {recipeDetail.Recipe.nutrientAnalysis.sodium.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Sodium :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.sodium.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.nutrientAnalysis.totalFat.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Total fat :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.totalFat.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.nutrientAnalysis.carbohydrate.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Total carbohydrate :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.carbohydrate.value}</Link>
                                                        </li>
                                                    </> : ""}


                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.nutrientAnalysis.saturatedFat.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Saturated fat :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.saturatedFat.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.nutrientAnalysis.fiber.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Dietary fiber :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.fiber.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                                <ul style={{ margin: "2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.nutrientAnalysis.cholesterol.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Cholesterol :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.cholesterol.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.nutrientAnalysis.monounsaturatedFat.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Monounsaturated Fat :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.monounsaturatedFat.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                                <ul style={{ margin: "2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.nutrientAnalysis.calcium.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('calcium :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.calcium.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.nutrientAnalysis.protein.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('protein :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.nutrientAnalysis.protein.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>

                                            </div>

                                        </div>

                                        <div className='col-md-6'>
                                            <h3 className='mb-5 pb-3'>{recipeDetail.Recipe.hwp_label}</h3>


                                            {/* {recipeDetail.Recipe.nutrientAnalysis.calories.value} */}
                                            <div className="social-icons social-colored social-fullwidth">
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.hwp.carbs.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Carbs :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.hwp.carbs.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.hwp.fats.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Fats :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.hwp.fats.value}</Link>
                                                        </li>
                                                    </> : ""}


                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.hwp.fruits.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Fruits :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.hwp.fruits.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.hwp.proteinDairy.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link> {t('proteinDairy :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.hwp.proteinDairy.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.hwp.sweets.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('sweets :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.hwp.sweets.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.hwp.vegetables.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('vegetables :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.hwp.vegetables.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>


                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <h3>{recipeDetail.Recipe.dash_label}</h3>


                                            <div className="social-icons social-colored social-fullwidth">
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.dash.dairy.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('dairy :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.dairy.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.dash.fats.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Fats :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.fats.value}</Link>
                                                        </li>
                                                    </> : ""}


                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.dash.fruits.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Fruits :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.fruits.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.dash.grains.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link> {t('grains :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.grains.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.dash.meat.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('meat :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.meat.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.dash.vegetables.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('vegetables :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.vegetables.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>

                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.dash.nutsSeedsDryBeans.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('nuts Seeds & Dry Beans :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.nutsSeedsDryBeans.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.dash.sweets.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('sweets :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.dash.sweets.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                            </div>

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>{recipeDetail.Recipe.diabetes_label}</h3>


                                            {/* {recipeDetail.Recipe.nutrientAnalysis.calories.value} */}
                                            <div className="social-icons social-colored social-fullwidth">
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.diabetes.fats.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('fats :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.fats.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.diabetes.freeFoods.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('freeFoods :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.freeFoods.value}</Link>
                                                        </li>
                                                    </> : ""}


                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.diabetes.fruits.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('Fruits :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.fruits.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.diabetes.milk.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link> {t('milk :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.milk.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.diabetes.meat.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('meat :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.meat.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.diabetes.nonStarchyVegetables.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('non Starchy Vegetables :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.nonStarchyVegetables.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>

                                                <ul style={{ margin: " 2px", display: 'table-row-group' }} className='ps-0 w-50'>
                                                    {recipeDetail.Recipe.diabetes.starches.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('starches :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.starches.value}</Link>
                                                        </li>
                                                    </> : ""}
                                                    {recipeDetail.Recipe.diabetes.sweets.value ? <>
                                                        <li className="theme-bg" style={{ padding: "1px" }}><Link>{t('sweets :')}</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>{recipeDetail.Recipe.diabetes.sweets.value}</Link>
                                                        </li>
                                                    </> : ""}

                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <CopyWrite />

                            </div>
                        </>)

                        )}
                    </div>
                </div>
            </section>

            {/* <!--blog end--> */}

        </div>

    </>
}
