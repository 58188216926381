import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Carousel from "react-bootstrap/Carousel";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import 'react-circular-progressbar/dist/styles.css';
import Cookies from "js-cookie";
import Select from 'react-select';
import { GetDepartmentsOptionsContext } from '../../../../Context/GetDepartmentsOptions';
import { GetDoctorsOptionsContext } from '../../../../Context/GetDoctorsOptions';
import axios from 'axios';
import { format } from 'date-fns';
import { PatientPortalPinInpContext } from '../../../../Context/Authentication/PatientPortalPinInpContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';

export default function Home() {

    // translation 
    const { t } = useTranslation("AlexHome");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    let { GetDoctorsSelectOptions, DoctorsOptions, selectedOption, setSelectedOption, setDoctorsOptions, selectedBranch, setSelectedBranch } = useContext(GetDoctorsOptionsContext);

    const { pathname } = useLocation();

    const client = {
        autoplay: true,
        loop: true,
        margin: 30,
        responsiveClass: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 3,
            },
            600: {
                items: 4,
            },

            1000: {
                items: 5,

            }
        },
    }


    const brancheOptions = [
        { value: '1', label: t('Cairo') },
        { value: '2', label: t('Alexandria') },
    ]
    const defaultValue = { value: '2', label: 'Alexandria' };

    // const [DoctorsOptions, setDoctorsOptions] = useState('');
    const handleSelectBranchChange = (selectedBranch) => {

        setSelectedBranch(selectedBranch);
        console.log('selectedBranch ' + selectedBranch.value);

    };
    const handleSelectChange = (selectedOption, DoctorsOptions) => {

        setSelectedOption(selectedOption);
        setDoctorsOptions(DoctorsOptions);

    };
    const handleDoctorSelectChange = (DoctorsOptions) => {
        // setSelectedOption(selectedOption);
        setDoctorsOptions(DoctorsOptions);

        // if (action === 'select-option') {
        //     setDoctorsOptions(DoctorsOptions);
        // } else if (action === 'clear') {
        //     setDoctorsOptions(null);
        // }

    };

    function preventDefault(e) {
        e.preventDefault();

    }
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState([]);
    let search = []
    function bookingResult() {

        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`)
    }
    // async function doctorSearch(e) {
    //     // e.preventDefault();
    //     // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`)
    //     if (selectedOption || DoctorsOptions) {
    //         let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/en?search_hospital=1&search_name=&search_title[]=&search_title[]=&search_title[]=&gender[]=&gender[]=&search_department=${selectedOption.value}&doc_id=${DoctorsOptions.value}`);
    //         setSearchData(data.Edata);
    //         <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`} />

    //         // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
    //     }

    // }
    let [currentPageForSearch, setCurrentPageForSearch] = useState(1)
    // let [lastPageOfSearch, setLastPageOfSearch] = useState()
    // let [perPageOfSearch, setPerPageOfSearch] = useState()
    // let [totalOfSearch, setTotalOfSearch] = useState()

    async function doctorSearch(e) {
        if (e) {
            e.preventDefault();
        }
        if (selectedOption || selectedBranch || DoctorsOptions) {
            // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} />

            let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/en?search_hospital=${selectedBranch.value}&search_name=&search_title[]=&search_title[]=&search_title[]=&gender[]=&gender[]=&search_department=${selectedOption.value}&doc_id=${DoctorsOptions.value}&page=${currentPageForSearch}&orderby=&order=`);
            setSearchData(data.Edata.data);
            // setSearchData(data.Edata.data);
            // setPerPageOfSearch(data.Edata.per_page)
            // setLastPageOfSearch(data.Edata.last_page)
            // setTotalOfSearch(data.Edata.total);
            // console.log('tototofsearch' + totalOfSearch);
            // setPerPageOfSearch(data.Edata.per_page)
            // setLastPageOfSearch(data.Edata.last_page)
            // setTotalOfSearch(data.Edata.total);
            // console.log('tototofsearch' + totalOfSearch);
            // setLoading(false);
            // document.getElementById('paginationForSearch').classList.remove('d-none');
            // document.getElementById('pagination').classList.add('d-none');

        }

    }
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    useEffect(() => {
        GetDepartmentsSelectOptions(selectedBranch.value);
    }, [selectedBranch])

    useEffect(() => {
        setSelectedBranch({ value: '2', label: t('Alexandria') })

    }, [])
    useEffect(() => {

        GetDoctorsSelectOptions();

    }, [selectedOption])


    useEffect(() => {
        if (selectedBranch) {
            search = searchData?.filter((doctorSearch) => selectedBranch === doctorSearch.branch)

        } else if (selectedOption) {
            search = searchData?.filter((doctorSearch) => selectedOption === doctorSearch.department_id)

        } else if (DoctorsOptions) {
            search = searchData?.filter((doctorSearch) => DoctorsOptions === doctorSearch.doc_id)

        } else {
            search = searchData?.map((doctorSearch))
        }
        doctorSearch();
    }
        , [selectedOption, selectedBranch, DoctorsOptions])
    useEffect(() => {
        doctorSearch();
        // if (doctorSearch()) {
        //     document.getElementById('pagination').classList.add('d-none');
        // } else {
        //     document.getElementById('pagination').classList.remove('d-none');

        // }

    }, [selectedOption, selectedBranch, DoctorsOptions])


    // events

    // const [allEvents, SetAllEvents] = useState([]);
    // async function getEvents(hospital_id) {
    //     const bodyFormData = new FormData();
    //     // Append data to the FormData object
    //     // bodyFormData.append('pin', pinNumInp);
    //     // bodyFormData.append('phone', phoneNumInp);
    //     const headers = {
    //         'lang': Cookies.get('i18next'),
    //         'fingerprint': localStorage.getItem('fingerPrint'),
    //     }

    //     const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/event/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}`)
    //         .then(response => {
    //             // Handle the response
    //             if (response.data.Ecode === 200) {
    //                 SetAllEvents(response.data.Edata);

    //                 console.log('all services ' + response.data.Edata.data);


    //             }

    //         })
    //         .catch(error => {
    //             // Handle any errors
    //             console.error(error);
    //         });

    // }
    // useEffect(() => {
    //     getEvents('1');
    // }, [])



    // ivp section

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }




    // get ivp 
    const [allIvp, setAllIvp] = useState([]);
    const [allIvpMsg, setAllIvpMsg] = useState('');

    async function getIvpDoctors(hospital_id) {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&is_vip=1`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setAllIvp(response.data.Edata)
                    setAllIvpMsg('');

                } else {
                    setAllIvpMsg(t('There is No Doctors'));

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        getIvpDoctors('2');
    }, []);


    // Booking
    let { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);

    const [showModal, setShowModal] = useState(false);
    const [showAvailableSlotsModal, setShowAvailableSlotsModal] = useState(false);
    const [showNextModal, setShowNextModal] = useState(false);
    const [showConfirmedModal, setShowConfirmedModal] = useState(false);
    const [showFreeSlotsModal, setShowFreeSlotsModal] = useState(false);
    const [showForgetPinModal, setShowForgetPinModal] = useState(false);
    const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [visible, setVisible] = useState(6);

    function loadMore() {
        setVisible(visible + 6);
    }


    let [doctorId, setDoctorId] = useState('');
    let [doctorsBooking, setDoctorsBooking] = useState([]);
    let [doctorData, setDoctorData] = useState({});
    let [doctorIdData, setDoctorIdData] = useState('');
    let [doctorDepartmentIdData, setDoctorDepartmentIdData] = useState('');
    let [doctorBranchData, setDoctorBranchData] = useState('');
    let [doctorNameData, setDoctorNameData] = useState('');
    // const [showAvailableSlotsModal, setShowAvailableSlotsModal] = useState(false);
    const handleOpenAvailableSlotsModal = (doc_id, dep_id, branch, doc_name) => {

        // const ButtonValue = event.target.value;
        const id = doc_id;
        const docBranch = branch;
        const dep = dep_id;
        const name = doc_name;
        setDoctorId(id);
        setDoctorBranchData(docBranch);
        setDoctorDepartmentIdData(dep);
        setDoctorNameData(name);
        console.log('doc id ' + doctorId);
        console.log('doc branch ' + doctorBranchData);
        console.log('doc dep-id ' + doctorDepartmentIdData);
        setShowAvailableSlotsModal(true);

        // if (id && docBranch && dep) {
        //     await axios.getFreeDays();
        //     // setShowAvailableSlotsModal(true);
        // }
        // setShowAvailableSlotsModal(true);
        // if (currentPage) {
        //     let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/${Cookies.get('i18next')}?page=${currentPage}`);
        //     // const filteredDoctors = data.Edata.filter((doctor) => doctor.department_name === departments);
        //     console.log(data);
        //     setDoctorsBooking(data.Edata.data);
        //     console.log(doctorsBooking);
        //     if (Array.isArray(doctorsBooking)) {
        //         const doctorList = doctorsBooking.find((doctorBooking) => doctorBooking.doc_id === doctorId);
        //         setDoctorData(doctorList);
        //         setDoctorBranchData(doctorList.branch);
        //         setDoctorDepartmentIdData(doctorList.department_id);
        //         setDoctorIdData(doctorList.doc_id);
        //         console.log('selected dr ' + doctorData);
        //         console.log('selected dr ' + doctorData);
        //         console.log('selected dr ' + doctorDepartmentIdData);
        //         console.log('selected dr ' + doctorBranchData);
        //         console.log('selected dr ' + doctorIdData);
        //         getFreeDays();
        //         setShowAvailableSlotsModal(true);
        //     }
        //     // if (doctorList) {

        //     // if (doctorData.doc_id && doctorData.department_id && doctorData.branch) {

        //     // }

        //     // }
        // }
        // getDoctorsList();


    };


    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             if (doctorId) {
    //                 const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/${Cookies.get('i18next')}?page=${currentPage}`);
    //                 const data = response.data.Edata.data;
    //                 setDoctorsBooking(data);

    //                 const doctorList = data.find((doctorBooking) => doctorBooking.doc_id === doctorId);
    //                 setDoctorData(doctorList);
    //                 setDoctorBranchData(doctorList.branch);
    //                 setDoctorDepartmentIdData(doctorList.department_id);
    //                 setDoctorIdData(doctorList.doc_id);
    //                 getFreeDays();
    //             }
    //         } catch (error) {
    //             // Handle the error
    //             console.error(error);
    //         }
    //     }

    //     fetchData();
    // }, [doctorId]);

    // useEffect(() => {
    //     if (Object.keys(doctorData).length > 0) {
    //         getFreeDays();
    //     // }
    // }, [doctorData])
    const [selectedDate, setSelectedDate] = useState();
    const handleOpenModal = () => {
        setShowModal(true);
        // setSelectedDate(date);
        // setShowAvailableSlotsModal(false)
    };
    const handleOpenNextModal = (date) => {
        setShowModal(false);
        setShowNextModal(true);
        setShowAvailableSlotsModal(false)
        setShowConfirmedModal(false);
        setShowOTPModal(false);
        setShowFirstTimeModal(false);
        setShowForgetPinModal(false);
        setShowFreeSlotsModal(false);
        setSelectedDate(date);
    };
    let [selectedTime, setSelectedTime] = useState('');
    let [selectedTimeTO, setSelectedTimeTO] = useState('');
    function handleOpenConfirmedModal(time, timeTo) {
        // const timeFrom = time;
        // const timeTill = timeTo;
        setSelectedTimeTO(timeTo);
        setSelectedTime(time);
        setShowConfirmedModal(true);
        setShowModal(false);
        setShowNextModal(false);
        setShowAvailableSlotsModal(false)
        setShowForgetPinModal(false);
        setShowFirstTimeModal(false);
        setShowOTPModal(false);
        setShowFreeSlotsModal(false)
        // bookNow();

    };


    const handleOpenForgetPinModal = () => {
        setShowForgetPinModal(true);
        setShowNextModal(false);
        setShowAvailableSlotsModal(false);
        setShowConfirmedModal(false);
        setShowFirstTimeModal(false);
        setShowModal(false);
        setShowOTPModal(false);
        setShowFreeSlotsModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenFirstTimeModal = () => {
        setShowFirstTimeModal(true);
        setShowNextModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenOTPModal = () => {
        setShowOTPModal(true);
        // setShowNextModal(false);
        setShowFirstTimeModal(false);

    };

    const handleCloseAvailableSlotsModal = () => {
        setShowAvailableSlotsModal(false);
        setFreeDays([]);
        setFreeDaysMsg('');
        document.getElementById('showAvailableSlotsModal').classList.add('d-none');

    };
    const handleCloseModal = () => {
        setShowModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseNextModal = () => {
        setShowNextModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseFreeSlotsModal = () => {
        setShowFreeSlotsModal(false);
        // document.querySelector('.modale').classList.add('d-none');
    }
    const handleCloseConfirmedModal = () => {
        setShowConfirmedModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseForgetPinModal = () => {
        setShowForgetPinModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseFirstTimeModal = () => {
        setShowFirstTimeModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseOTPModal = () => {
        setShowOTPModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };

    //  Api for get free_days for Booking
    let [freeDays, setFreeDays] = useState([]);
    let [freeDaysMsg, setFreeDaysMsg] = useState();
    // let [firstFree, setFirstFree] = useState([]);
    let [freeDaysOptions, setFreeDaysOptions] = useState([]);

    const getFreeDays = async () => {

        const bodyFormData = new FormData();
        bodyFormData.append('doc_id', doctorId);
        bodyFormData.append('departmentid', doctorDepartmentIdData);
        // bodyFormData.append('lang',  Cookies.get('i18next'));

        if (pinInp.length > 0) {
            bodyFormData.append('pin', pinInp);
        } else {
            bodyFormData.append('pin', '0');
        }

        bodyFormData.append('branch', doctorBranchData);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }


        try {

            const response = await axios.post('https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/free_days', bodyFormData, { headers });

            if (response.data.Ecode === 200) {
                if (response.data.Edata.length === 0) {
                    setFreeDaysMsg('There Are No Available Slots');
                    setShowAvailableSlotsModal(true);

                } else {
                    setFreeDays(response.data.Edata);
                    // document.getElementById('showOTPModal').classList.add('d-none');
                    // // document.getElementById('ShowAvailableSlotsModal').classList.add('d-none');
                    // document.getElementById('showNextModal').classList.add('d-none');
                    // document.getElementById('showFreeSlotsModal').classList.add('d-none');
                    // document.getElementById('showConfirmedModal').classList.add('d-none');
                    // document.getElementById('showFirstTimeModal').classList.add('d-none');
                    // document.getElementById('showForgetPinModal').classList.add('d-none');
                    setShowAvailableSlotsModal(true);
                    console.log(freeDays);
                    setFreeDaysOptions(response.data.fselect);
                    console.log(response.data.fselect);
                    setFreeDaysMsg('');
                }
            } else {
                setFreeDays(response.data.Edata);

                setFreeDaysMsg(response.data.Emsg);
                console.log(freeDaysMsg);
                // document.getElementById('showOTPModal').classList.add('d-none');
                // // document.getElementById('ShowAvailableSlotsModal').classList.add('d-none');
                // document.getElementById('showNextModal').classList.add('d-none');
                // document.getElementById('showFreeSlotsModal').classList.add('d-none');
                // document.getElementById('showConfirmedModal').classList.add('d-none');
                // document.getElementById('showFirstTimeModal').classList.add('d-none');
                // document.getElementById('showForgetPinModal').classList.add('d-none');
                setShowAvailableSlotsModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const [formData, setFormData] = useState({ pin: '' });
    const [pinNumInp, setPinNumInp] = useState({});
    const handleChange = (e) => {

        let myUser = { ...formData };
        myUser.pin = e.target.value

        setPinNumInp(myUser.pin);

        console.log('setPinNumInp ' + pinNumInp);

    };

    // checkPatientPhone
    const [formPhoneNumData, setFormPhoneNumData] = useState({ phone: '' });
    const [phoneNumInp, setPhoneNumInp] = useState({});
    const [patienName, setPatienName] = useState();
    const [pinError, setPinError] = useState(false);

    const handlePhoneNumChange = (e) => {

        let myUserPhone = { ...formPhoneNumData };
        myUserPhone.phone = e.target.value;

        setPhoneNumInp(myUserPhone.phone);

        console.log('setPinNumInp ' + phoneNumInp);

    };

    async function checkPatientPhone() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', pinNumInp);
        bodyFormData.append('phone', phoneNumInp);
        bodyFormData.append('branch', doctorBranchData);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/checkpatientphone`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setPatienName(response.data.Edata.name)
                    console.log('check phone ' + response.data.Edata);
                    setShowConfirmedModal(false);
                    setShowFreeSlotsModal(true);
                    setShowNextModal(false);
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setShowModal(false);
                    setShowAvailableSlotsModal(false);
                    setPinError(false);
                    getFreeSlots();

                } else {
                    handleOpenNextModal();
                    setPinError(response.data.Emsg);
                    setPinForgetMsg('');
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }



    // free slots 
    const [freeSlots, setFreeSlots] = useState([]);
    const [date, setDate] = useState();
    const [FreeSlotsMsg, setFreeSlotsMsg] = useState();
    let [dateValue, setDateValue] = useState();
    const handleSelectedDate = (dateValue) => {

        setSelectedDate(dateValue.value);
        console.log(selectedDate);
        // setDoctorSelectedOption(doctorSelectedOption);
        // setCurrentPageForSearch(1)

    };

    async function getFreeSlots() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('doc_id', doctorId);
        bodyFormData.append('departmentid', doctorDepartmentIdData);
        bodyFormData.append('pin', pinNumInp);
        bodyFormData.append('branch', doctorBranchData);
        bodyFormData.append('date', selectedDate);
        console.log('selectedDate ' + selectedDate);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/freeslots`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    if (response.data.Edata === '') {
                        setFreeSlotsMsg(t('OOPs there is no available slots in'));
                    } else {
                        setDate(response.data.Edata.date);
                        setFreeSlots(response.data.Edata.slots);
                        setFreeSlotsMsg('');
                    }
                    // console.log('free slots ' + freeSlots.slots.map(slot => slot.time));

                } else {
                    setFreeSlotsMsg(response.data.Emsg);

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }

    // useEffect(() => {
    //     if (selectedDate) {
    //         getFreeSlots();

    //     }
    // }, [selectedDate])

    // forget pin number
    const [userIdInp, setUserIdInp] = useState();
    const [pinForgetMsg, setPinForgetMsg] = useState();
    const [userPhoneInp, setuserPhoneInp] = useState();
    const [pinForgetMsgError, setPinForgetMsgError] = useState();
    async function forgetPinNumber(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', '0');
        bodyFormData.append('phone', userPhoneInp);
        bodyFormData.append('userid', userIdInp);
        bodyFormData.append('branch', doctorBranchData);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/checkpatientphone`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 202) {
                    // setCheckPhoneNum(response.data.Emsg);
                    // setPinForgetMsg(response.data.Emsg)
                    console.log('handleOpenForgetPinModal ' + response.data);
                    console.log('handleOpenForgetPinModal ' + response.data.Emsg);
                    setPinForgetMsg(response.data.Emsg)
                    setShowNextModal(true);
                    setShowAvailableSlotsModal(false);
                    setShowFreeSlotsModal(false);
                    setShowConfirmedModal(false);
                    setShowFirstTimeModal(false);
                    setShowForgetPinModal(false);
                    setShowOTPModal(false);
                    setShowModal(false);
                    // checkPatientPhone();
                } else {
                    setPinForgetMsgError(response.data.Emsg)

                    handleOpenForgetPinModal();
                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }


    // booking 

    function preventDefault(e) {
        e.preventDefault();
    }
    const [bookingMsg, setBookingMsg] = useState('');
    async function bookNow() {
        const bodyFormData = new FormData();
        if (patientNewPin) {
            bodyFormData.append('pin', -(patientNewPin));

        } else {
            bodyFormData.append('pin', pinNumInp);

        }
        if (patientPhone) {
            bodyFormData.append('phone', patientPhone);

        } else {
            bodyFormData.append('phone', phoneNumInp);

        }
        bodyFormData.append('branch', doctorBranchData);
        bodyFormData.append('date', selectedDate);

        bodyFormData.append('time_from', selectedTime);
        bodyFormData.append('time_to', selectedTimeTO);
        bodyFormData.append('clinic_id', doctorDepartmentIdData);
        bodyFormData.append('doc_id', doctorId);

        console.log('selectedDate ' + selectedDate);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/book`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Ecode === 200) {

                    // Handle the response
                    setBookingMsg(response.data.Emsg);
                    setShowConfirmedModal(true);
                    setShowModal(false);
                    setShowNextModal(false);
                    setShowAvailableSlotsModal(false)
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setShowFreeSlotsModal(false)
                } else {
                    setShowConfirmedModal(true);
                    setShowFreeSlotsModal(false);
                    setShowAvailableSlotsModal(false);
                    setShowNextModal(false);
                    setShowModal(false);
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setBookingMsg(response.data.Emsg);

                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }



    // New Registration 

    const [patientNewPin, setPatientNewPin] = useState('');
    const [patientName, setPatientName] = useState();
    const [patientPhone, setPatientPhone] = useState('');
    const [patientBirthDate, setPatientBirthDate] = useState();
    const [patientGender, setPatientGender] = useState();
    const [patientRegistrationError, setPatientRegistrationError] = useState();
    async function registerNewPatient(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        bodyFormData.append('name', patientName);
        bodyFormData.append('phone', patientPhone);
        bodyFormData.append('birthday', patientBirthDate);
        bodyFormData.append('gender', patientGender);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/RegisterNewPatient`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Emsg === "Success") {
                    // Handle the response
                    console.log(response.data);
                    setPatientNewPin(response.data.Edata.pin);
                    handleOpenOTPModal();
                } else {
                    setPatientRegistrationError(response.data.Emsg);
                    setShowOTPModal(false);
                    setShowFirstTimeModal(true);
                }

                // setCheckPhoneNum(response.data.Emsg);
                // } else {
                //     setShowConfirmedModal(true);
                //     setShowFreeSlotsModal(false);
                //     setShowAvailableSlotsModal(false);
                //     setShowNextModal(false);
                //     setShowForgetPinModal(false);
                //     setShowFirstTimeModal(false);
                //     setShowOTPModal(false);
                //     setBookingMsg(response.data.Emsg);

                // }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }

    // check code for New patient registrtion
    const [patientCode, setPatientCode] = useState();
    const [patientCodeError, setPatientCodeError] = useState();
    async function checkNewPatientCode(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        bodyFormData.append('pin', patientNewPin);
        bodyFormData.append('phone', patientPhone);
        bodyFormData.append('birthday', patientBirthDate);
        bodyFormData.append('gender', patientGender);
        bodyFormData.append('otpcode', patientCode);
        bodyFormData.append('branch', doctorBranchData);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo_test/public/patientportal/booking/CheckCode`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Ecode === 200) {
                    // Handle the response
                    // setPatientRegistration(response.data);
                    console.log(response.data);
                    // handleOpenOTPModal();
                    setShowOTPModal(false);
                    setShowFreeSlotsModal(true);

                    // freeSlots();

                    // setShowAvailableSlotsModal(true);

                } else {
                    setShowOTPModal(true);
                    setPatientCodeError(response.data.Emsg)
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        if (selectedTime !== '' && selectedTimeTO !== '') {
            bookNow();
        }
    }, [selectedTime, selectedTimeTO]);

    // useEffect(() => {
    //     if (selectedTime.length > 0 && selectedTimeTO.length > 0 && Object.keys(pinNumInp).length > 0 && Object.keys(phoneNumInp).length > 0 && doctorDepartmentIdData.length > 0 && doctorId.length > 0) {
    //         bookNow();
    //         console.log('selectedTimeTO ' + selectedTimeTO);
    //         console.log('selectedTime ' + selectedTime);
    //     } else if (selectedTime.length > 0 && selectedTimeTO.length > 0 && patientPhone.length > 0 && patientNewPin.length > 0 && doctorDepartmentIdData.length > 0 && doctorId.length > 0) {
    //         bookNow();
    //     } else {


    //     }
    // }, [selectedTime, selectedTimeTO, pinNumInp, phoneNumInp, doctorDepartmentIdData, doctorId, patientPhone, patientNewPin]);
    // let { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);

    useEffect(() => {
        if (doctorId && doctorBranchData && doctorDepartmentIdData) {
            getFreeDays();
            setShowAvailableSlotsModal(true);
            // setShowOTPModal(false);

        }
    }, [doctorId, doctorBranchData, doctorDepartmentIdData, pinInp]);

    // useEffect(() => {
    //     getFreeDays();
    // }, [])

    //////////////////////////////////////////////////////////////////////////////////////////
    // get services
    const [allServices, SetAllServices] = useState([]);
    async function getServices(hospital_id) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/service/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    SetAllServices(response.data.Edata);
                    // console.log('all services ' + response.data.Edata.data);

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getServices('2');
    }, [])

    // get department
    const [allSpecialities, setAllSpecialities] = useState([]);
    async function getSpecialities(hospital_id) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/department/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    setAllSpecialities(response.data.Edata);



                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getSpecialities('2');
    }, [])

    // full screen for virtual tour iframe 
    const iframeRef = useRef(null);

    const enterFullScreen = () => {
        const iframe = iframeRef.current;

        if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
            iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
            iframe.webkitRequestFullscreen();
        } else if (iframe.msRequestFullscreen) {
            iframe.msRequestFullscreen();
        }
    };

    function cairoNavbar() {
        // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo`} />
        document.getElementById('normal-nav').classList.add('d-none');
        document.getElementById('alex-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.remove('d-none');
        document.getElementById('referTo').classList.remove('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');
        scrollToTop()
    }
    function alexNavbar() {
        document.getElementById('normal-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.add('d-none');
        document.getElementById('alex-nav').classList.remove('d-none');
        document.getElementById('referTo').classList.remove('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');
        scrollToTop()

    }
    return <>

        {/* <!--hero section start--> */}





        <section class="grey-bg fullscreen-banner p-0 overflow-hidden d-md-none d-block">
            <Carousel loop={true} controls={false} indicators={true} slide={true}>

                <Carousel.Item class="item" data-bg-img="/images/mob-version/ALEX/alex-branch--min.webp" style={{ backgroundImage: `url('/images/mob-version/ALEX/alex-branch--min.webp')` }}>
                    <div className="align-center w-60 end-0 d-flex justify-content-end flex-column opacity-0">
                        <h5 className="text-white mb-5 animated6" data-wow-delay="4s"><span className="theme-bg span-ivp ">{t('International Visiting Professors')}</span></h5>

                        <h4 className='w-100 text-center bg-white bg-opacity-50 time-ivp text-theme mb-0'> <i className='fas fa-calendar-alt text-theme me-1'></i> {t('24-29 Feb 2024')}
                        </h4>
                        <h3 className="mb-0 w-100 text-center animated5 theme-bg text-white fw-bold ivp-name" >{t('PROF. Khalid elbatarji')}</h3>

                        <h6 className="w-100 text-center bg-white bg-opacity-50 fw-bold dep-ivp text-theme mb-0 animated5" > {t('Consultant Of Orthopedic')}
                            <br />
                            <p className='fw-light mb-2'>Berater für Orthopädie</p>
                            <img src="/images/icons/germany.png" alt="" />
                        </h6>

                        {/* <p className='lead fs-4'>Faculty of Natural Sciences Mb. Bch.</p> */}

                    </div>
                </Carousel.Item>
                <Carousel.Item class="item" data-bg-img="/images/mob-version/ALEX/mobile_shuttle_bus.webp" style={{ backgroundImage: `url('/images/mob-version/ALEX/mobile_shuttle_bus.webp')` }}>
                    <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/shuttle-bus`} onClick={scrollToTop}>

                        <div className="align-center ms-auto w-60 end-0 ms-auto opacity-0">
                            <h5 className="text-white mb-5 animated6" data-wow-delay="4s"><span className="theme-bg span-ivp ">{t('International Visiting Professors')}</span></h5>

                            <h4 className='w-100 text-center bg-white bg-opacity-50 time-ivp text-theme mb-0'> <i className='fas fa-calendar-alt text-theme me-1'></i> {t('24-29 Feb 2024')}
                            </h4>
                            <h3 className="mb-0 w-100 text-center animated5 theme-bg text-white fw-bold ivp-name" >{t('PROF. Khalid elbatarji')}</h3>

                            <h6 className="w-100 text-center bg-white bg-opacity-50 fw-bold dep-ivp text-theme mb-0 animated5" > {t('Consultant Of Orthopedic')}
                                <br />
                                <p className='fw-light mb-2'>Berater für Orthopädie</p>
                                <img src="/images/icons/germany.png" alt="" />
                            </h6>

                            {/* <p className='lead fs-4'>Faculty of Natural Sciences Mb. Bch.</p> */}

                        </div>
                    </Link>
                </Carousel.Item>

            </Carousel>
        </section >


        <section className="grey-bg p-0 position-relative d-md-block d-none" >
            <Carousel loop={true} controls={true} indicators={false} slide={true} >
                <Carousel.Item class="item">

                    <img
                        src="/images/Saudi German Health Sliders/alex-branch--min.webp"
                        className="d-block w-100 "
                        alt="screenshot"

                    />

                </Carousel.Item>
                <Carousel.Item class="item">
                    <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/shuttle-bus`} onClick={scrollToTop}>

                        <img
                            src="/images/Saudi German Health Sliders/shuttle-bus-desktop.webp"
                            className="d-block w-100 "
                            alt="screenshot"

                        />


                        <div className="pt-0 text-center opacity-0">
                            <div className="container">
                                <div className="row text-end justify-content-end align-items-center ms-5 ps-5">
                                    <div className="col-lg-8 col-md-8 text-center">
                                        <h5 className="text-white letter-space-1 mb-5 animated6" data-wow-delay="4s"><span className="px-5 py-2 theme-bg fs-6 span-ivp ">{t('International Visiting Professors')}</span></h5>
                                        <div className="align-center w-50 end-0">
                                            <h4 className='w-75 bg-white bg-opacity-50 p-4 time-ivp text-theme mb-0'> <i className='fas fa-calendar-alt text-theme me-1'></i> {t('24-29 Feb 2024')}
                                            </h4>
                                            <h3 className="mb-0 w-75 animated5 theme-bg p-3 text-white fw-bold ivp-name" >{t('PROF. Khalid elbatarji')}</h3>

                                            <h6 className="w-75 bg-white bg-opacity-50 p-3 fw-bold dep-ivp text-theme mb-0 animated5" > {t('Consultant Of Orthopedic')}
                                                <br />
                                                <p className='fw-light mb-2'>Berater für Orthopädie</p>
                                                <img src="/images/icons/germany.png" alt="" />
                                            </h6>

                                            {/* <p className='lead fs-4'>Faculty of Natural Sciences Mb. Bch.</p> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Carousel.Item>

            </Carousel >


        </section >

        <div className="page-content">

            <section className="grey-bg py-4">
                <div class="container">
                    <div className='row text-center justify-content-center mb-5'>
                        <div class="section-title mb-md-5 mb-2">
                            {i18n.language === 'en' ? <h2 class="title mb-3">{t('Virtual')}

                                <span> {t('Tour')}</span> </h2> : <h2 class="title mb-3">{t('Tour')}

                                <span> {t('Virtual')}</span> </h2>}
                        </div>
                        <div className="col-md-10 position-relative">
                            <button onClick={enterFullScreen} aria-pressed="true" className='fullscreen-btn' title="Full Screen">
                                <img aria-hidden="true" src="/images/icons/full-screen-svgrepo-com.svg" />

                                <span class="sr-only">{t('Undo Repost')}</span>
                            </button>

                            <iframe ref={iframeRef} src="https://my.matterport.com/show/?m=2BqAdWu5eY3" width="100%" height="450vw"></iframe>
                        </div>
                    </div>

                    <div class="row text-center">

                        <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
                            <div class="section-title mb-md-5 mb-2">
                                {i18n.language === 'en' ? <h2 class="title mb-5">{t('Comprehensive')} <span>{t('Medical Care')}</span> </h2> : <h2 class="title mb-5">{t('Medical Care')} <span>{t('Comprehensive')}</span> </h2>}
                                <div class="section-title mb-md-5 mb-2 d-flex justify-content-between align-items-center">

                                    <h2 className='fw-bold fs-4 mb-0'>{t('Our Services')}</h2>

                                    {/* <p class="mb-0">Hectolab Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p> */}
                                    <button className='btn btn-theme btn-sm appoint-btn rounded-top rounded-bottom'><Link onClick={scrollToTop} className='text-white' to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services`}>{t('See More')} </Link>  </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-3 mb-3">
                        {/* <Carousel indicators={true} slide={true}> */}
                        {allServices.slice(0, 3).map((allService, index) => <>
                            {/* <Carousel.Item key={index} className="item"> */}

                            <div className="col-md-4" key={index}>
                                <div class="post">
                                    <div class="post-image">
                                        {allService.image ? <img class="img-fluid" src={`${allService.image}`} alt="" /> : <img class="img-fluid" src="/images/service/01.jpg" alt="" />}
                                        {/* <img class="img-fluid" src="/images/service/01.jpg" alt="" /> */}
                                    </div>
                                    <div class="post-desc">
                                        {/* <div class="post-meta">
                                                        <ul class="list-inline">
                                                            <li>23 April, 2019</li>
                                                        
                                                        </ul>
                                                    </div> */}
                                        <div class="post-title">
                                            <h5><Link to="">{allService.famous_name}</Link></h5>
                                        </div>
                                        <p style={{ aspectRatio: 9 / 2 }} dangerouslySetInnerHTML={{ __html: allService.shortnote.replace(/^(.{200}\S*).*$/, '$1') }}></p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services/${allService.famous_name}/${allService.id}`}>{t('Read More')}
                                            {i18n.language === 'en' ? <i class="ms-2 fas fa-long-arrow-alt-right"></i> : <i class="ms-2 fas fa-long-arrow-alt-left"></i>}
                                        </Link>
                                    </div>
                                </div>
                            </div>




                            {/* </Carousel.Item> */}

                        </>)}
                    </div>
                    {/* </Carousel> */}

                    <div class="row text-center ">
                        <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
                            <div class="section-title mb-md-5 mb-2 d-flex justify-content-between align-items-center">
                                <h2 className='fw-bold fs-4 mb-0'>{t('Our Specialities')}</h2>
                                {/* <h2 class="title">Our <span> Specialities </span></h2> */}
                                {/* <p class="mb-0">Hectolab Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p> */}
                                <button className='btn btn-theme btn-sm appoint-btn rounded-top rounded-bottom'><Link onClick={scrollToTop} className='text-white' to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties`}>{t('See More')} </Link>  </button>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-3 mb-3">
                        {/* <Carousel indicators={true} slide={true}> */}
                        {allSpecialities.slice(0, 3).map((allSpecialitie, index) => <>
                            {/* <Carousel.Item key={index} className="item"> */}

                            <div className="col-md-4" key={index}>
                                <div class="post">
                                    <div class="post-image">
                                        {allSpecialitie.image ? <img class="img-fluid" src={`${allSpecialitie.image}`} alt="" /> : <img class="img-fluid" src="/images/service/01.jpg" alt="" />}
                                        {/* <img class="img-fluid" src="/images/service/01.jpg" alt="" /> */}
                                    </div>
                                    <div class="post-desc">
                                        {/* <div class="post-meta">
                                                        <ul class="list-inline">
                                                            <li>23 April, 2019</li>
                                                        
                                                        </ul>
                                                    </div> */}
                                        <div class="post-title">
                                            <h5><Link to="">{allSpecialitie.famous_name}</Link></h5>
                                        </div>
                                        <p style={{ aspectRatio: 9 / 2 }} dangerouslySetInnerHTML={{ __html: allSpecialitie.brief.replace(/^(.{200}\S*).*$/, '$1') }}></p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/${allSpecialitie.famous_name}/${allSpecialitie.id}`}>{t('Read More')}
                                            {i18n.language === 'en' ? <i class="ms-2 fas fa-long-arrow-alt-right"></i> : <i class="ms-2 fas fa-long-arrow-alt-left"></i>}
                                        </Link>
                                    </div>
                                </div>
                            </div>




                            {/* </Carousel.Item> */}

                        </>)}
                    </div>
                </div >

            </section >


            <section className='p-2'>
                <div class="container">
                    <div class="row text-center">
                        <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                            <div class="section-title mb-md-5 mb-2">
                                {/* <h6>Team</h6> */}
                                {i18n.language === 'en' ? <h2 class="title"> {t('International Visiting')} <span> {t('Professors')}</span></h2> : <h2 class="title"> {t('Professors')} <span> {t('International Visiting')}</span></h2>}
                                {/* <p> Elevating Healthcare Beyond Borders </p> */}
                                <p class="mb-3">{t('ivpParagraph')} </p>
                                <button className='btn btn-theme btn-sm appoint-btn mt-sm-0 mt-3 rounded-top rounded-bottom'><Link onClick={scrollToTop} className='text-white' to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/ivp`}>{t('All Doctors')}</Link>  </button>

                            </div>
                        </div>
                    </div>
                    <div class="row gy-3">
                        {allIvp.slice(0, 3).map((ivp, index) => <>
                            <div className="col-lg-4 col-md-6" key={index}>
                                <div className="team-member text-center">
                                    <div className="team-images">
                                        {ivp.image ? <img className="img-fluid" src={ivp.image} alt="" /> : <img className="img-fluid" src="/images/team/01.jpg" alt="" />}
                                        <div className="team-about">
                                            <p dangerouslySetInnerHTML={{ __html: ivp.pio.replace(/^(.{200}\S*).*$/, '$1') }}></p>
                                            {/* <span className='text-white'>... Read More</span> */}
                                            <div className="team-social-icon">
                                                <ul>
                                                    <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/${ivp.branch === 'القاهره' ? 'cairo' : (ivp.branch === 'الاسكندريه' ? 'alex' : ivp.branch)}/ivp/${ivp.doc_id}`} onClick={(ivp.branch === 'القاهره' || ivp.branch === 'cairo') ? cairoNavbar : alexNavbar}><li className='bg-white p-2 rounded-3'>{t('Full Profile')}
                                                    </li></Link>
                                                    <Link onClick={() => handleOpenAvailableSlotsModal(ivp.doc_id, ivp.department_id, ivp.branch, ivp.famous_name)}><li className='bg-white p-2 rounded-3'>{t('Book Now')}

                                                    </li></Link>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="team-description"> <span>{ivp.department_name}</span>
                                        <h5><Link to="/">{ivp.famous_name}</Link></h5>
                                        {/* <span className='text-muted fs-5'> <i className="fas fa-map-marker-alt text-muted small"></i>  {ivp.branch}</span> */}

                                        {/* <span className='text-muted date'> {ivp.department_name}</span> */}
                                    </div>
                                </div>
                            </div>
                        </>)}


                    </div>
                </div>
            </section>
            {/* <!--team start--> */}

            {/* <section className="grey-bg">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xl-8 col-lg-10 col-md-12 mx-auto">
                            <div className="section-title mb-md-5 mb-2">
                                <h6>News</h6>
                                <h2 className="title">What's <span>News</span></h2>
                                <p className="mb-0">Hectolab Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div class="post-queto text-white mt-5">
                            <Carousel class="owl-carousel no-pb owl-loaded owl-drag" controls={false} indicators={true} slide={true}>
                                <Carousel.Item class="item">
                                    <div className='row'>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                                                    <img className="img-fluid" src={`../../images/blog/01.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                                                    <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                                                </div>

                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                                        <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                                                    </div>


                                                    <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                                                    </p>

                                                </div>






                                            </div>

                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                                                    <img className="img-fluid" src={`../../images/blog/01.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                                                    <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                                                </div>

                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                                        <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                                                    </div>


                                                    <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                                                    </p>

                                                </div>






                                            </div>

                                        </div>

                                    </div>


                                </Carousel.Item>

                                <Carousel.Item class="item">
                                    <div className='row'>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                                                    <img className="img-fluid" src={`../../images/blog/02.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                                                    <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                                                </div>

                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                                        <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                                                    </div>


                                                    <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                                                    </p>

                                                </div>






                                            </div>

                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                                                    <img className="img-fluid" src={`../../images/blog/02.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                                                    <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                                                </div>

                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                                        <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                                                    </div>


                                                    <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                                                    </p>

                                                </div>






                                            </div>

                                        </div>

                                    </div>
                                </Carousel.Item>

                                <Carousel.Item class="item">
                                    <div className='row'>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                                                    <img className="img-fluid" src={`../../images/blog/03.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                                                    <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                                                </div>

                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                                        <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                                                    </div>


                                                    <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                                                    </p>

                                                </div>





                                            </div>

                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                                                    <img className="img-fluid" src={`../../images/blog/03.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                                                    <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                                                </div>

                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                                        <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                                                    </div>


                                                    <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                                                    </p>

                                                </div>






                                            </div>

                                        </div>


                                    </div>
                                </Carousel.Item>
                            </Carousel>

                        </div>
                    </div>
                </div>
            </section > */}

            {/* <!--team end--> */}

            {/* accreditaion */}
            {/* <section class="text-center py-1" style={{ background: 'linear-gradient(270deg, #40ad56 0%, #007438 100%)' }}>
                <div class="container">
                    <div class="row justify-content-between">

                        <div class="col-md-3">
                            <img class="img-fluid d-inline w-50" src="/images/about/accreditation-section/JCI -min.webp" alt="" />
                        </div>
                        <div class=" col-md-3">
                            <img class="img-fluid d-inline w-50" src="/images/about/accreditation-section/canadian -min.webp" alt="" />
                        </div>
                        <div class=" col-md-3">
                            <img class="img-fluid d-inline w-50" src="/images/about/accreditation-section/Gahar -min.webp" alt="" />
                        </div>



                    </div>
                </div>
            </section> */}

            {/* <section >
                <div className="container">
                    <div className="row text-center justify-content-between">
                        <div className="col-md-6 teams-container">
                            <div class="section-title mb-md-5 mb-2 mb-0">
                                <h2 class="title">Success<span> Stories</span></h2>
                                <div className='text-center pt-5 d-flex justify-content-between align-items-center'>
                                    <h6 className='text-center' style={{ color: '#87d95d' }}>
                                        Saudi German Hospital Success Stories
                                    </h6>
                                </div>
                                <div className="d-sm-flex mb-3 teams">
                                    <div class="widget">
                                        <div class="recent-post">
                                            <ul class="list-unstyled">
                                                <li class="mb-3">
                                                    <div class="recent-post-thumb">
                                                        <img class="img-fluid" src="../../../images/blog/blog-thumb/01.jpg" alt="" />
                                                    </div>
                                                    <div class="recent-post-desc"> <Link to="https://www.youtube.com/watch?v=ew_BhbmE_4E" className='text-decoration-underline fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. </Link>
                                                        <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                                    </div>
                                                </li>
                                                <li class="mb-3">
                                                    <div class="recent-post-thumb">
                                                        <img class="img-fluid" src="../../../images/blog/blog-thumb/01.jpg" alt="" />
                                                    </div>
                                                    <div class="recent-post-desc"> <Link to="https://www.youtube.com/watch?v=ew_BhbmE_4E" className='text-decoration-underline fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. </Link>
                                                        <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                                    </div>
                                                </li>
                                                <li class="mb-3">
                                                    <div class="recent-post-thumb">
                                                        <img class="img-fluid" src="../../../images/blog/blog-thumb/02.jpg" alt="" />
                                                    </div>
                                                    <div class="recent-post-desc"> <Link to="https://www.youtube.com/watch?v=ew_BhbmE_4E" className='text-decoration-underline fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. </Link>
                                                        <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="recent-post-thumb">
                                                        <img class="img-fluid" src="../../../images/blog/blog-thumb/03.jpg" alt="" />
                                                    </div>
                                                    <div class="recent-post-desc"> <Link to="https://www.youtube.com/watch?v=ew_BhbmE_4E" className='text-decoration-underline fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. </Link>
                                                        <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="recent-post-thumb">
                                                        <img class="img-fluid" src="../../../images/blog/blog-thumb/03.jpg" alt="" />
                                                    </div>
                                                    <div class="recent-post-desc"> <Link to="https://www.youtube.com/watch?v=ew_BhbmE_4E" className='text-decoration-underline fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. </Link>
                                                        <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 teams-container">
                            <div class="section-title mb-md-5 mb-2 mb-0">

                                <h2 class="title">Our <span>Events</span></h2>

                            </div>
                            <div className='text-center pt-5 d-flex justify-content-between align-items-center'>
                                <h6 className='text-center' style={{ color: '#87d95d' }}>UpComming
                                    <h6 className='text-muted w-25 pt-2 text-center d-inline'> Events

                                    </h6>
                                </h6>
                                <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/facilities-events`} class="btn btn-sm btn-simple"><span>All Events <i class="ms-2 fas fa-long-arrow-alt-right"></i></span></Link>




                            </div>
                            {allEvents.slice(0, 4).map((event, index) => <>
                                <Link key={index} onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/event-details/${event.title}/${event.id}`}>
                                    <div className="d-sm-flex mb-3 teams p-0 justify-content-start">
                                        <div className="flex-shrink-0 overflow-hidden d-flex flex-column justify-content-center align-items-center p-4 text-white fs-5 fw-bold " style={{ background: '#16dce2' }}>
                                            <h6 className='text-white fw-bolder fs-4'>{new Date(event.start_date).getDate()}</h6><span>{format((new Date(event.start_date).getMonth() + 1), "MMM")}</span>
                                        </div>

                                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0 text-start pb-2">
                                            <div className="comment-date mt-2"> <span className="date me-2 fs-6 fw-bold text-muted">{event.title} </span>


                                            </div>


                                            <p className='text-muted m-0 small pe-2'>{event.shortnote}
                                            </p>

                                        </div>






                                    </div>
                                </Link>
                            </>)}
                           
                        </div>
                    </div>
                </div>
            </section> */}

        </div >

        {/* <!--body content end-->  */}

        < Modal className='modale fade' id='showAvailableSlotsModal' size="lg" scrollable show={showAvailableSlotsModal} onHide={handleCloseAvailableSlotsModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title id="contained-modal-title-vcenter"><span className='text-black'> {t('Available Slots')} </span> <span className='text-theme fw-bolder'> - {doctorNameData}</span></Modal.Title>

            </Modal.Header>
            <Modal.Body>

                <div className="row justify-content-center calender-box text-center g-3">
                    {freeDaysMsg ? <h6> {freeDaysMsg}</h6> : ''}
                    {freeDays.length > 0 ?
                        freeDays?.map(freeDay => <>
                            <div className="col-md-3">
                                {/* <h4> {freeDaysMsg}</h4> */}
                                <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={() => handleOpenNextModal(freeDay.day)}>
                                    <div className=" appointment-box bg-mute rounded-0"><p className='m-0 text-black'>
                                        <span className='d-block text-theme'>{format(new Date(freeDay.day), 'EEEE')}</span>
                                        {freeDay.day}</p>
                                    </div>
                                    <div className="inner-box m-3" style={{ border: '1px solid #409639' }} >
                                        <div className="date px-2"><h6 className='m-0'>
                                            <span>{(parseInt(freeDay.first_free.split(':')[0], 10) >= 8 && parseInt(freeDay.last_free.split(':')[0], 10) <= 16) ? t('Morining') : (parseInt(freeDay.first_free.split(':')[0], 10) >= 16 && parseInt(freeDay.last_free.split(':')[0], 10) <= 23) ? t('Evening') : t('Morning,Evening')}</span>
                                        </h6></div>
                                        <div className="appointment-box theme-bg"><h6>{t('Book Now')}</h6></div>
                                    </div >
                                </div >

                            </div >
                        </>) : ''}

                    {/* {freeDays?.map(freeDay => <>
            <div className="col-md-3">
               
                <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={() => handleOpenNextModal(freeDay.day)}>
                    <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>{freeDay.day}</h6>
                    </div>
                    <div className="inner-box m-3" style={{ border: '1px solid #409639' }} >
                        <div className="date px-1"><h4> <span className='pe-1'> {moment(freeDay.first_free, 'HH:mm:ss').format('h:mm A')}</span><span>  {moment(freeDay.last_free, 'HH:mm:ss').format('h:mm A')} </span></h4></div>
                        <div className="appointment-box theme-bg"><h6>Book Now</h6></div>
                    </div >
                </div >

            </div >
        </>)} */}

                    {/* <div className="col-md-3">
            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                </div>
                <div className="inner-box m-3" style={{ border: '1px solid #409639' }
                } onClick={handleOpenModal} >
                    <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                    <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                </div >
            </div >
        </div >
        <div className="col-md-3">
            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                </div>
                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                    <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                    <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                </div >
            </div >
        </div >
        <div className="col-md-3">
            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                </div>
                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                    <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                    <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                </div >
            </div >
        </div >
        <div className="col-md-3">
            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                </div>
                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                    <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                    <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                </div >
            </div >
        </div >
        <div className="col-md-3">
            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                </div>
                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                    <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                    <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                </div >
            </div >
        </div >
        <div className="col-md-3">
            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                </div>
                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                    <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                    <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                </div >
            </div >
        </div >
        <div className="col-md-3">
            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                </div>
                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                    <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                    <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                </div >
            </div >
        </div > */}

                </div >


            </Modal.Body >
            <Modal.Footer className='justify-content-between align-items-center ps-2'>
                {freeDays ? <>
                    <div className="text-start">

                        <ul>
                            <li><span className='text-theme fw-bold'>{t('Morning Shifts')} :</span> {t('from')} 8 am {t('to')} 4 pm </li>
                            <li><span className='text-theme fw-bold'>{t('Evening Shifts')} :</span> {t('from')} 4 pm {t('to')} 11 pm </li>

                        </ul></div></> : ''}
                {/* <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseAvailableSlotsModal}>
                    Close
                </Button> */}
            </Modal.Footer>
        </Modal >

        {/* < !--body content end-- >  */}
        {/* Nex Modal*/}
        <Modal className='modale fade vertical-align-middle' id="showNextModal" centered scrollable show={showNextModal} onHide={handleCloseNextModal}>
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>

                    {t('Please Enter Your Data')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center text-center">
                    {pinError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {pinError}</div> : ''}
                    {pinForgetMsg ? <div className="portal-success text-white  my-2"> {pinForgetMsg}</div> : ''}

                    <form className="row justify-content-center text-center" onSubmit={preventDefault} >
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="pin" onChange={handleChange} className="form-control" placeholder={t("Pin Number")}
                                required="required" data-error="Valid Patient ID is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6 mt-xs-3">
                            <input id="form_id2" type="text" name="phone" className="form-control" onChange={handlePhoneNumChange} placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="col-sm-12 mt-1  mb-3">
                            <span className='small'><Link onClick={handleOpenForgetPinModal}>{t('Forget My Pin Number')} </Link></span>
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                            <button className="btn btn-theme btn-radius" onClick={checkPatientPhone}><span>{t('Confirmed')}</span>
                            </button>
                        </div>

                        <div className="col-md-8">
                            <button className="btn btn-theme btn-radius w-100" onClick={handleOpenFirstTimeModal}><span>{t('This Is My First Time')}</span>
                            </button>
                        </div>
                    </form>

                </div>

            </Modal.Body>
            {/* <Modal.Footer>
    <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseNextModal}>
        Close
    </Button>
</Modal.Footer> */}
        </Modal >

        {/* free slots  */}

        < Modal className='modale fade' size="lg" scrollable id='showFreeSlotsModal' show={showFreeSlotsModal} onHide={handleCloseFreeSlotsModal} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title> <span className='text-black'> {t('Free Slots')}</span> <span className='text-theme fw-bolder'> -  {doctorNameData}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center calender-box text-center gy-3">
                    {FreeSlotsMsg ? <h6>{FreeSlotsMsg}</h6> : <>
                        <div className="team-description text-center row flex-column align-items-center mb-3">
                            <h4 className='text-muted mt-2'>👋 {t('Hello')} {patienName}</h4>
                            <p className='mt-2 '> {t('Here is Your Available Slots and You can Change your choice for day')} </p>
                            <div className="col-md-5">

                                <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" >
                                    <i className="fa-regular fa-calendar text-main px-2 border-end" style={{ fontSize: '18px' }}></i>

                                    <Select options={freeDaysOptions} placeholder={date} value={dateValue} className="w-100 ps-3" onChange={handleSelectedDate} styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                            outline: state.isFocused ? ' none' : 'none',
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                            backgroundColor: state.isFocused ? '#409639' : ' ',
                                            color: state.isFocused ? '#fff' : '',

                                        }),
                                    }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                    </Select>
                                </div>
                            </div>
                        </div>
                    </>}
                    {/* <div className="team-description text-center row flex-column align-items-center mb-3">
                        <h4 className='text-muted mt-2'>👋 Hello {patienName}</h4>
                        <p className='mt-2 '> Here is Your Available Slots and You can Change your choice for day </p>
                        <div className="col-md-5">

                            <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" >
                                <i className="fa-regular fa-calendar text-main px-2 border-end" style={{ fontSize: '18px' }}></i>

                                <Select options={freeDaysOptions} placeholder={date} value={dateValue} className="w-100 ps-3" onChange={handleSelectedDate} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                        outline: state.isFocused ? ' none' : 'none',
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                        backgroundColor: state.isFocused ? '#409639' : ' ',
                                        color: state.isFocused ? '#fff' : '',

                                    }),
                                }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                </Select>
                            </div>
                        </div>
                    </div> */}
                    {freeSlots?.map((freeSlot, index) => <>
                        <div className="col-md-3" key={index}>
                            {/* {freeDaysMsg ? <h4>{freeDaysMsg}</h4> : ''} */}
                            <div className="inner-box" style={{ width: '100%', border: '1px solid #409639' }} onClick={() => handleOpenConfirmedModal(freeSlot.time, freeSlot.time_to)} >

                                <div className="date px-1"><h4> <span>{moment(freeSlot.time, 'HH:mm:ss').format('h:mm A')} </span> : <span>{moment(freeSlot.time_to, 'HH:mm:ss').format('h:mm A')}</span></h4></div>
                                <div className="appointment-box theme-bg"><h6>{t('Book Now')}</h6></div>
                            </div >

                        </div >

                    </>
                    )}





                </div>

            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseFreeSlotsModal}>
                    {t('Close')}
                </Button>
            </Modal.Footer>
        </Modal >
        {/* confirmed Modal */}
        < Modal className='modale fade vertical-align-middle' scrollable id='showConfirmedModal' show={showConfirmedModal} onHide={handleCloseConfirmedModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>                    {t('Your Message')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center my-5">
                    <div className="team-description text-center">
                        <Link to='' className='h3'>{bookingMsg}</Link>
                        {/* {bookingMsg ? <p className='mt-5 '> If you need to change or cancel your booking, please call 16259.</p> : ''} */}

                    </div>

                </div>

            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseConfirmedModal}>
                    {t('Close')}
                </Button>
            </Modal.Footer>
        </Modal >

        {/* forget Pin MODAL*/}
        < Modal className='modale fade vertical-align-middle' centered id='showForgetPinModal' show={showForgetPinModal} onHide={handleCloseForgetPinModal} >
            <Modal.Header closeButton>
                <img src='../../images/cropped-9952-img-32x32.jpg' alt="sgh Icon" />

                <Modal.Title>                   {t('Forget Your Pin')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {pinForgetMsgError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {pinForgetMsgError}</div> : ''}
                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={forgetPinNumber} method="get" action="">
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input type="text" name="id" className="form-control" placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." value={userPhoneInp} onChange={(e) => setuserPhoneInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6">
                            <input type="number" name="id" className="form-control" placeholder={t("National ID")}
                                required="required" data-error="Valid National ID is required." minLength={14} maxLength={14} value={userIdInp} onChange={(e) => setUserIdInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>{t('Confirmed')}</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
    <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseForgetPinModal}>
        Close
    </Button>
</Modal.Footer> */}
        </Modal >

        {/* my first time MODAL*/}
        <Modal className='modale fade vertical-align-middle' centered id='showFirstTimeModal' show={showFirstTimeModal} onHide={handleCloseFirstTimeModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>   {t('Enter Your Data')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {patientRegistrationError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {patientRegistrationError}</div> : ''}
                    <form className="row justify-content-center text-center" onSubmit={registerNewPatient} method='post'>
                        <div className="messages"></div>
                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="Fullname" value={patientName} onChange={(e) => setPatientName(e.target.value)} className="form-control" placeholder={t("Full Name")}
                                required="required" data-error="Valid Full Name is required." />
                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <select className="form-select form-control" required="required" value={patientGender} onChange={(e) => setPatientGender(e.target.value)} data-error="Valid Gender is required.">
                                <option value="- Select Gender">{t('- Select Gender')}</option>
                                <option value="1">{t('Male')}</option>
                                <option value="2">{t('Female')}</option>
                            </select>

                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <input id="form_number1" type="text" name="id" className="form-control" value={patientPhone} onChange={(e) => setPatientPhone(e.target.value)} placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="form-group col-sm-6">
                            <input type="date" className="form-control" value={patientBirthDate} onChange={(e) => setPatientBirthDate(e.target.value)} placeholder={t("Date")} id="datepicker2" />
                        </div>
                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>{t('Register')}</span>
                            </button>
                        </div>


                    </form>
                </div >

            </Modal.Body >
            {/* <Modal.Footer>
    <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseFirstTimeModal}>
        Close
    </Button>
</Modal.Footer> */}
        </Modal >
        {/* OTP MODAL */}

        < Modal className='modale fade vertical-align-middle' centered id='showOTPModal' show={showOTPModal} onHide={handleCloseOTPModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>   {t('Enter Your OTP')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {patientCodeError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {patientCodeError}</div> : ''}
                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={checkNewPatientCode} method="post">
                        <div className="messages"></div>


                        <div className="form-group col-sm-6">
                            <input type="text" name="id" className="form-control" placeholder={t("Code")}
                                required="required" data-error="Valid Code is required." onChange={(e) => setPatientCode(e.target.value)} value={patientCode} />
                            <div className="help-block with-errors"></div>
                        </div>


                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>{t('Confirmed')}</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
    <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseOTPModal}>
        Close
    </Button>
</Modal.Footer> */}
        </Modal >

    </>
}
